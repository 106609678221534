import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useCart } from 'react-use-cart';
import CardContent from '@mui/material/CardContent';
import MKBox from "../MKBox";
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { ConvertPrice, capitalizeAndReplaceUnderscores} from '../product/ProductUtils';  // Assume this utility is imported correctly
import StateImage from 'components/images/StateImages';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Tooltip } from '@mui/material';

const ShoppingCartLineItem = (props) => {
    const {
        removeItem
    } = useCart();

    return (
        <Grid container spacing={2} key={props.item.id}>
            <Grid item xs={.2} md={.2}></Grid>
            <Grid item xs={12} md={6} lg={4}>
                <MKBox position="relative" borderRadius="lg" mx={2} mt={2} sx={{background:'white', height: { xs: '150px', md: '200px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MKBox
                        component="img"
                        src={StateImage(props.item.product_name.replace(/ /g, '_'))}
                        alt={props.item.product_name}
                        borderRadius="lg"
                        width="auto"
                        height="100%"
                        position="relative"
                        zIndex={1}
                    />
                    <MKBox
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="100%"
                        position="absolute"
                        left={0}
                        top={0}
                        sx={{
                            backgroundImage: `url(${props.item.image_path})`,
                            transform: "scale(0.94)",
                            filter: "blur(12px)",
                            backgroundSize: "cover",
                        }}
                    />
                </MKBox>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
            <CardContent>
                        <Typography variant="h5" component="div" >
                            {props.item.product_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {capitalizeAndReplaceUnderscores(props.item.product_type)} {props.item.product_year}
                        </Typography>
                    </CardContent>

            </Grid>
            <Grid item xs={.6} md={.6} lg={.1} ></Grid>
            <Grid item xs={12} md={6} lg={1.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                    ${ConvertPrice(props.item.price)}
                </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Tooltip title="Remove from cart" arrow  color="secondary">
                    <Button className="btn btn-danger" onClick={() => removeItem(props.item.id)}>
                        <DeleteForeverIcon color="secondary" />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={.2} md={.2} lg={2} ></Grid>
        </Grid>
    );
};

export default ShoppingCartLineItem;
