import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";
import StateImage from 'components/images/StateImages';  
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
import DonutChart from './charts/DonutChart';
import { useTheme, useMediaQuery } from '@mui/material';
import BarChartCompare from './charts/BarChartCompare';
import Pyramid from './charts/Pyramid';
import VerticalBarChart from './charts/VerticalBarChart';
import StateSVGComponent from 'components/images/StateSVGs';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const ProductDetail = () => {
    const { stateName } = useParams();
    const [dataDefinitions, setDataDefinitions] = useState(null);
    const [stateData, setStateData] = useState(null);
    const [usaData, setUsaData] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const fetchData = useCallback(async (endpoint) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state-income-data/${endpoint}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching ${endpoint} data:`, error);
            return null;
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchAllData = async () => {
            const [definitions, usa] = await Promise.all([
                fetchData('data_definitions'),
                fetchData('usa')
            ]);
            setDataDefinitions(definitions);
            setUsaData(usa);

            if (stateName && stateName.toLowerCase() !== 'usa') {
                const state = await fetchData(stateName.toLowerCase());
                setStateData(state);
            }
        };
        fetchAllData();

    }, [setDataDefinitions, setUsaData, setStateData,stateName,fetchData]);


    const getStateUrl = (stateName) => {
        return `/income-by-zipcode/${stateName.replace(/\s+/g, '_')}`;
      };
    
    const makeStatePretty = () => {
        if (stateName.toLowerCase() === 'usa') {
            return 'the USA';
        }
        const formattedStateName = stateName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        return formattedStateName || 'The State';
    };
    const createDataStructure = (keys, definitions, stateData) => {
        const result = {
          title: '',
          type: 'count',
          data: {}
        };
        if (!stateData || !stateData.data) {
          return {
            title: '',
            data: keys.reduce((acc, key) => {
              acc[String(key)] = 0;
              return acc;
            }, {})
          };
        }
        keys.forEach(key => {
          if (definitions[key]) {
            result.title = definitions[key]['all_households'] || result.title;
            result.type = definitions[key]['type'] || result.type;
            result.data[definitions[key]['column_name']] = stateData.data[key] !== undefined && stateData.data[key] !== null
              ? stateData.data[key]
              : 0;
          }
        });
        if (Object.values(result.data).every(value => value === 0 || value === null)) {
          result.title = 'Income';
          result.data = keys.reduce((acc, key) => {
            acc[String(key)] = 0;
            return acc;
          }, {});
        }
        return result;
      };
    

    const donut_colors =['#36A2EB', '#FF6384'];
    const donut_height = isMobile ? '100px' : '175px';
    const bar_height = isMobile ? '400px' : '400px';
    const pyramid_height = isMobile ? '200px' : '500px';
    const bar_colors = ["#FFCE56", "#4BC0C0"]

    const mean_income_state = createDataStructure( ["S1901_C01_012E","S1901_C01_013E"], dataDefinitions, stateData );
    const mean_income_usa = createDataStructure( ["S1901_C01_012E","S1901_C01_013E"], dataDefinitions, usaData );
    const ageDistribution_state = createDataStructure( ["S1903_C03_011E","S1903_C03_012E","S1903_C03_013E","S1903_C03_014E"], dataDefinitions, stateData );
    const ageDistribution_usa = createDataStructure( ["S1903_C03_011E","S1903_C03_012E","S1903_C03_013E","S1903_C03_014E"], dataDefinitions, usaData );
    const wo_wage_salary = createDataStructure( ["B19052_002E", "B19052_003E"], dataDefinitions, stateData );
    const wo_retirement_income = createDataStructure( ["B19059_002E", "B19059_003E"], dataDefinitions, stateData );
    const wo_earnings = createDataStructure( ["B19051_002E", "B19051_003E"], dataDefinitions, stateData );
    const wo_self_employment = createDataStructure( ["B19053_002E", "B19053_003E"], dataDefinitions, stateData );
    const wo_social_security = createDataStructure( ["B19055_002E", "B19055_003E"], dataDefinitions, stateData );
    const wo_ssi = createDataStructure( ["B19056_002E", "B19056_003E"], dataDefinitions, stateData );
    const wo_public_assistance = createDataStructure( ["B19057_002E", "B19057_003E"], dataDefinitions, stateData );
    const wo_cash_public_assistance = createDataStructure( ["B19058_002E", "B19058_003E"], dataDefinitions, stateData );
    const distribution_state = createDataStructure( ["S1901_C01_002E","S1901_C01_003E","S1901_C01_004E","S1901_C01_005E","S1901_C01_006E","S1901_C01_007E","S1901_C01_008E","S1901_C01_009E","S1901_C01_010E","S1901_C01_011E"], dataDefinitions, stateData );
    const distribution_usa = createDataStructure( ["S1901_C01_002E","S1901_C01_003E","S1901_C01_004E","S1901_C01_005E","S1901_C01_006E","S1901_C01_007E","S1901_C01_008E","S1901_C01_009E","S1901_C01_010E","S1901_C01_011E"], dataDefinitions, usaData );


    return (
        <>
    <MKBox component="section" py={1} sx={{ 
            backgroundColor: "#dcf0fa", 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
            paddingTop: '90px',
            paddingBottom: '20px',
        }}>
        <Container maxWidth="xl" >
            <Box sx={{
                height: '100%',
                width: '100%',
                padding: { xs: 2, md: 3 },
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                <Grid item xs={12}>
                    <MKTypography variant="h2" align="center" sx={{ fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' } }}>
                    {makeStatePretty()} Income Data Summary
                    </MKTypography>
                </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        padding: { xs: 2, md: 3 },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start', // Align items to the start (left)
                        overflow: 'hidden'
                    }}>
                        <CardContent sx={{ width: '100%' }}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <MKTypography variant="h4" align="left" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                                        Median State Income: ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[0]]).toLocaleString('en-US')}
                                    </MKTypography>
                                </Grid>
                                <Grid item>
                                    <MKTypography variant="h4" align="left" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem'} }}>
                                        Average State Income: ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[1]]).toLocaleString('en-US')}
                                    </MKTypography>
                                </Grid>
                                <Grid item>
                                    {parseInt(mean_income_state.data[Object.keys(mean_income_state.data)[1]], 10) < parseInt(mean_income_usa.data[Object.keys(mean_income_usa.data)[1]], 10) ? (
                                        <MKTypography variant="body1" align="left" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }, color: 'red' }}>
                                            <TrendingDownIcon sx={{ fontSize: '2rem' }} />
                                            The mean income in {makeStatePretty()} is ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[1]]).toLocaleString('en-US')},
                                            which is lower than the national average of ${Number(mean_income_usa.data[Object.keys(mean_income_usa.data)[1]]).toLocaleString('en-US')}.
                                        </MKTypography>
                                    ) : (
                                        <MKTypography variant="body1" align="left" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }, color: 'green' }}>
                                            <TrendingUpIcon sx={{ fontSize: '2rem' }} />
                                            The mean income in {makeStatePretty()} is ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[1]]).toLocaleString('en-US')},
                                            which is higher than the national average of ${Number(mean_income_usa.data[Object.keys(mean_income_usa.data)[1]]).toLocaleString('en-US')}.
                                        </MKTypography>
                                    )}
                                    {parseInt(mean_income_state.data[Object.keys(mean_income_state.data)[0]], 10) < parseInt(mean_income_usa.data[Object.keys(mean_income_usa.data)[0]], 10) ? (
                                        <MKTypography variant="body1" align="left" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }, color: 'red' }}>
                                            <TrendingDownIcon sx={{ fontSize: '2rem' }} />
                                            The median income in {makeStatePretty()} is ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[0]]).toLocaleString('en-US')},
                                            which is lower than the national average of ${Number(mean_income_usa.data[Object.keys(mean_income_usa.data)[0]]).toLocaleString('en-US')}.
                                        </MKTypography>
                                    ) : (
                                        <MKTypography variant="body1" align="left" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }, color: 'green' }}>
                                            <TrendingUpIcon sx={{ fontSize: '2rem' }} />
                                            The median income in {makeStatePretty()} is ${Number(mean_income_state.data[Object.keys(mean_income_state.data)[0]]).toLocaleString('en-US')},
                                            which is higher than the national average of ${Number(mean_income_usa.data[Object.keys(mean_income_usa.data)[0]]).toLocaleString('en-US')}.
                                        </MKTypography>
                                    )}
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid item sx={{ height: { xs: '250px', sm: '300px', md: '400px' }, width: '100%' }}>
                        <VerticalBarChart
                            data1={mean_income_state.data}
                            data2={mean_income_usa.data}
                            colors={bar_colors}
                            label_text1={`${stateData?.state_name_pretty || ''}`}
                            label_text2="USA"
                            title_text={`${stateData?.state_name_pretty || ''} vs USA ${mean_income_state.title}`}
                            currency={'USD' }
                        />
                    </Grid>
                </Grid>
            </Grid>
                </Box>
            {/* end income distribution block */}
            {/* map block */}
            <Grid item xs={12} md={6}>
                <Box sx={{
                        height: '100%',
                        width: '100%',
                        background: 'white',
                        padding: { xs: 2, md: 3 },
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }}>
                    <CardContent sx={{ width: '100%' }}>
                        <Grid container direction="column" spacing={2} xs={12}>
                            <MKTypography variant="h4" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                            {makeStatePretty()} Median Income Map
                            </MKTypography>
                            <MKTypography variant="body1" sx={{ 
                                textAlign: 'left', 
                                lineHeight: 1.5,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }
                            }}>
                                The map shows a gradient of median income for all ZIP codes in {makeStatePretty()}. 
                                The ZIP codes are split into 5 groups, with the lightest color representing the lowest income and the darkest color representing the highest income. 
                            </MKTypography>
                        </Grid>
                    </CardContent>
                    <Grid container spacing={2} direction="row"> 
                        <Grid item xs={12} md={6}>
                            <Box sx={{
                                height: '100%',
                                width: '100%',
                                padding: { xs: 2, md: 3 },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start', // Align items to the start (left)
                                overflow: 'hidden'
                            }}>

                                <CardContent sx={{ width: '100%' }}>
                                    <Grid container direction="column" spacing={2} xs={12}>
                                        <MKTypography variant="h4" align="left" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                                            Explore Median Income Distribution in {makeStatePretty()}
                                        </MKTypography>
                                        <MKTypography variant="body1" align="left" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' } }}>
                                            Discover the median income distribution across various income demographics for {makeStatePretty()}, including:
                                            <ul>
                                                <li>Mean/Median income for Families and Households</li>
                                                <li>Mean/Median income for Married-couple families</li>
                                                <li>Mean/Median income for Nonfamily households</li>
                                                <li>Median Household Income by Age groups: 15-24, 25-44, 45-64, 65+</li>
                                                <li>Median income for Female and Male households without a spouse</li>
                                                <li>Median Family Income by Family Size (2 to 7+ members)</li>
                                                <li>Median Family Income by Number of Earners (0 to 3+ earners)</li>
                                            </ul>
                                            This map visualizes the income distribution across all zip codes in {makeStatePretty()}, providing insights into economic trends and disparities.
                                        </MKTypography>
                                        <MKButton
                                            component={Link}
                                            to={getStateUrl(stateName)}
                                            variant="contained"
                                            color={"primary"}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '10px 15px',
                                                fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                                                minWidth: 'max-content',
                                                '& .MuiButton-startIcon': { 
                                                margin: 0,
                                                marginBottom: '8px',
                                                }
                                            }}
                                            aria-label={`Purchase income data for ${makeStatePretty()}`}
                                            >
                                                <span>Get {makeStatePretty()} income data</span>
                                        </MKButton>
                                    </Grid>
                                </CardContent>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>  
                            <MKTypography  align="Center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.5rem' } }}>
                                Color Key:
                            </MKTypography>
                            <ul style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                padding: 0, 
                                margin: 0,
                                listStyleType: 'none',
                                justifyContent: 'center'
                            }}>
                                <li style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span style={{ backgroundColor: '#ffffd9', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    Lowest Median Income
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span style={{ backgroundColor: '#c7e9b4', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    Low Median Income
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span style={{ backgroundColor: '#41b6c4', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    Medium Median Income
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span style={{ backgroundColor: '#1d91c0', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    High Median Income
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span style={{ backgroundColor: '#253494', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    Highest Median Income
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ backgroundColor: '#D1EFF7', display: 'inline-block', width: '18px', height: '18px', border: '1px solid black', marginRight: '5px' }}></span>
                                    No Zipcode
                                </li>
                            </ul>
                            <img 
                                    loading="lazy" 
                                    src={StateImage(stateName.replace(/ /g, '_'))}
                                    alt={`Median Income data visualization by zipcode for ${makeStatePretty()}`}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        // backgroundColor: 'black',
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                />
                                <MKTypography variant="body2" sx={{ 
                                    textAlign: 'center', // Center the text
                                    lineHeight: 1.5,
                                    fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                                    marginTop: 2
                                }}>
                                </MKTypography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Container>

    </MKBox>
    <Container maxWidth="xl" >

            {/* end map block */}
            <Box sx={{
                height: '100%',
                width: '100%',
                padding: { xs: 2, md: 3 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                    <CardContent sx={{ width: '100%', paddingTop: '10px'  }}>
                        <Grid container direction="column" spacing={2} xs={12}>
                            <MKTypography variant="h4" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                            {makeStatePretty()} Income Distribution
                            </MKTypography>
                            <Grid container direction="column" align="center" spacing={2} xs={12}>
                                <Pyramid 
                                    distribution_1={distribution_state.data}
                                    distribution_2={distribution_usa.data}
                                    label_text1={stateData?.state_name_pretty || ''}
                                    label_text2='USA'
                                    title_text={`${stateData?.state_name_pretty || ''} vs USA Household Income Percent Distribution`}
                                    currency='$'
                                    pyramid_height={pyramid_height}
                                />
                            </Grid>
                            <MKTypography variant="body1" sx={{ 
                                textAlign: 'left', 
                                lineHeight: 1.5,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }
                            }}>
                                The graph illustrates the household income percent distribution for {makeStatePretty()} compared to the national average.
                                The teal bars represent {makeStatePretty()}, while the yellow bars represent the USA. 
                                This visualization allows for a clear comparison of income distribution patterns between {makeStatePretty()} and the national average, highlighting areas where {makeStatePretty()} may have higher or lower percentages of households in each income bracket.
                            </MKTypography>
                        </Grid>
                    </CardContent>

                    <CardContent sx={{ width: '100%', paddingTop: '10px' }}>
                        <Grid container direction="column" spacing={2} xs={12}>
                            <MKTypography variant="h4" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                            {makeStatePretty()} Income Distribution by Age
                            </MKTypography>
                            <Grid container direction="column" align="center" spacing={2} xs={12}>
                                <BarChartCompare
                                    data1={ageDistribution_state.data}
                                    data2={ageDistribution_usa.data}
                                    colors={bar_colors}
                                    label_text1={`${stateData?.state_name_pretty || ''} Median Income`}
                                    label_text2="USA Median Income"
                                    title_text={`${stateData?.state_name_pretty || ''} vs USA ${ageDistribution_state.title}`}
                                    currency={'USD' }
                                    height={bar_height}
                                />
                            </Grid>
                            <MKTypography variant="body1" sx={{ 
                                textAlign: 'left', 
                                lineHeight: 1.5,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }
                            }}>
                                This graph compares the median household income by age group for {makeStatePretty()} and the USA, highlighting 
                                differences in income levels across various demographics. Each age category shows the median income for {makeStatePretty()} in yellow 
                                and the national average in teal, allowing for a clear visual comparison. 
                                This visualization reveals how income varies by age, providing insights into economic trends and disparities between the state and the national average.
                            </MKTypography>
                        </Grid>
                    </CardContent>
                </Box>
        <Grid item xs={12} md={6}  sx={{ marginTop: 4, alignContent: 'center'}}>
                <MKBox sx={{
                        height: '100%',
                        width: '80%', // Adjust width as needed
                        bgColor: 'gray',
                        padding: { xs: 2, md: 3 },
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center', // Center items horizontally
                        margin: '0 auto', // Center the box
                        overflow: 'hidden'
                    }}>
                    <CardContent sx={{ width: '100%' }}>
                        <Grid container direction="column" spacing={2} xs={12}>
                            <MKTypography variant="h4" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                            {makeStatePretty()} Types of Earning by Household
                            </MKTypography>
                            <MKTypography variant="body1" sx={{ 
                                textAlign: 'left', 
                                lineHeight: 1.5,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' }
                            }}>
                                The series of pie charts illustrates the distribution of various income types among a population, highlighting the presence or absence of specific income sources.
                                Each chart categorizes individuals based on whether they receive income from:
                                <ul style={{ Align: 'center' }}>
                                    <li>Wages or Salaries</li>
                                    <li>Retirement</li>
                                    <li>Self-Employment</li>
                                    <li>Public Assistance</li>
                                    <li>Social Security</li>
                                    <li>Supplemental Security Income (SSI)</li>
                                    <li>Household Earnings</li>
                                </ul>
                                The blue segments represent those with the respective income type, while the pink segments indicate those without it, providing a clear visual representation of income diversity and reliance on different financial support systems within the community. 
                                This data can help identify economic trends and the prevalence of various income sources among different demographics.
                            </MKTypography>
                        </Grid>
                    </CardContent>

            <Grid item xs={12} sx={{ width: '100%', overflow: 'visible' }}>
                <MKBox component="section" py={3} sx={{ textAlign: "center", mx: "auto", overflow: 'hidden' }} >
                    <Grid container flexDirection="column" alignItems="center"
                        sx={{ 
                            textAlign: "center", 
                            background: 'dark', 
                        }}
                    >
                        <div style={{ textAlign: 'left', marginBottom: '1vh', maxWidth: '70%' }}>
                            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_wage_salary} colors={donut_colors} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_retirement_income} colors={donut_colors}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_social_security} colors={donut_colors}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_self_employment} colors={donut_colors} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_cash_public_assistance} colors={donut_colors} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_ssi} colors={donut_colors}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_public_assistance} colors={donut_colors}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box sx={{ height: donut_height, width: '100%', margin: 'auto' }}>
                                                <DonutChart chartData={wo_earnings} colors={donut_colors}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                </MKBox>


                    </Grid>
                </MKBox>
            </Grid>


    <MKBox component="section" py={3} sx={{ textAlign: "center", mx: "auto" }} >
       
    </MKBox>
    </Container>

    </>

    );
};
export default ProductDetail;





