/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import { useMediaQuery } from '@mui/material';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import StateImage from "components/images/StateImages";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import StateSVGComponent from 'components/images/StateSVGs';

function MapsToProducts() {
  const [selectedState, setSelectedState] = useState(null);
  const [currentStateImage, setCurrentStateImage] = useState('usa');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md')); // Check if the screen size is small or smaller

  const allStates = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
    "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
    "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
    "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
    "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Usa"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const randomState = allStates[Math.floor(Math.random() * allStates.length)];
      setCurrentStateImage(randomState.toLowerCase().replace(/\s+/g, '_'));
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const handleStateSelect = (state) => {
    setSelectedState(state);
    // You can add additional logic here if needed
  };

  const image = StateImage(currentStateImage);
  const title = "Income By Zip Code";
  const description = "Select a state to view its median income map and access our comprehensive income reports. Perfect for real estate professionals, marketers, and researchers looking for precise demographic insights.";

  const getStateUrl = (stateName) => {
    return `/income-by-zipcode/${stateName.replace(/\s+/g, '_')}`;
  };

  return (
    <MKBox component="section" py={2} width="100%" bgcolor="background.paper">
      <Container maxWidth={false} sx={{ width: '100%', px: { xs: 2, sm: 3, md: 4 } }}>
        <Card sx={{ width: '100%', bgcolor: 'grey.100' }}>
          <MKBox p={3}>
            <MKBox sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mb: 4 }}>
                {isSmallScreen ? (
                    <MKBox 
                        display="flex" // Use flexbox for centering
                        justifyContent="center" // Center horizontally
                        alignItems="center" // Center vertically
                        sx={{ 
                        width: { xs: '150px', sm: '150px', md: '50%' }, // Fixed size for small and extra small screens, half width for larger
                        height: { xs: '150px', sm: '150px', md: 'auto' }, // Fixed height for small and extra small screens
                        margin: '0 auto' // Center the parent box within its container
                        }} 
                    >
                        <MKBox
                            component="img"
                            src={image}
                            alt={title}
                            borderRadius="lg"
                            width="100%" // Image takes full width of the container
                            height="100%" // Image takes full height of the container
                            sx={{ 
                                objectFit: 'contain' // Ensures the image fills the box
                            }} 
                        />
                    </MKBox>
                ) : (
                    <MKBox 
                        position="relative" 
                        borderRadius="lg" 
                        overflow="hidden" 
                        sx={{ 
                            maxHeight: '400px', 
                            width: '50%', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            paddingLeft: '5%',
                            paddingRight: '5%'
                        }} // Centering the content
                    >
                        <MKBox
                            component="img"
                            src={image}
                            alt={title}
                            borderRadius="lg"
                            width="100%" // Image takes full width of the container
                            height="auto" // Maintains aspect ratio
                            sx={{ 
                            maxHeight: { ss: '150px', md: '100%' }, // Fixed height for small screens
                            objectFit: 'contain' // Ensures the image fits within the container
                            }} 
                        />
                    </MKBox>
                )}
              {/* Right side - Text Content */}
              <MKBox sx={{ width: { xs: '100%', md: '60%' } }}>
                <MKTypography variant="h3" fontWeight="bold" mb={2} color="teal">
                  {title}
                </MKTypography>
                <MKTypography variant="body1" component="p" color="text" mb={3}>
                  {description}
                </MKTypography>
                <MKTypography variant="body1" component="p" color="text" mb={3}>
                  Our comprehensive income reports provide:
                </MKTypography>
                <ul>
                  <li>
                    <MKTypography variant="body1" component="p" color="text" mb={1}>
                      Detailed median and mean income data for every zip code
                    </MKTypography>
                  </li>
                  <li>
                    <MKTypography variant="body1" component="p" color="text" mb={1}>
                      Comprehensive income demographic data such as household earners by age, type, and gender
                    </MKTypography>
                  </li>
                  <li>
                    <MKTypography variant="body1" component="p" color="text" mb={3}>
                      Downloadable reports for in-depth analysis
                    </MKTypography>
                  </li>
                </ul>
                <MKButton
                  component={Link}
                  to={getStateUrl("rectangle_usa")}
                  variant="gradient"
                  size="large"
                  color="teal"
                  aria-label={`View map of median and mean household income data for the entire USA and purchase more detailed data`}
                >
                  View USA Income Data
                </MKButton>
              </MKBox>
            </MKBox>
            <MKTypography variant="h3" align="center" fontWeight="bold" mb={2} color="teal">
                See a Specific State
            </MKTypography>
            <MKBox mt={2} p={1} bgcolor="grey.200" borderRadius="lg">
              <Grid container spacing={2} justifyContent="center">
                {allStates.map((state, index) => (
                  <Grid item key={index}>
                    <MKButton
                      component={Link}
                      to={getStateUrl(state)}
                      variant="contained"
                      color={selectedState === state ? "primary" : "secondary"}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 15px',
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                        minWidth: 'max-content',
                        '& .MuiButton-startIcon': { 
                          margin: 0,
                          marginBottom: '8px',
                        }
                      }}
                      onClick={() => handleStateSelect(state)}
                      aria-label={`View map of median and mean household income data for ${state} and purchase more detailed data`}
                    >
                      <StateSVGComponent stateName={state} size={24} color={"white"}/>
                      <span>{state}</span>
                    </MKButton>
                  </Grid>
                ))}
              </Grid>
            </MKBox>
          </MKBox>
        </Card>
      </Container>
    </MKBox>
  );
}

export default MapsToProducts;
