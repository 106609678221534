import React from 'react';
import {  Link } from "react-router-dom"
import ReturnButton from './basics/ReturnButton';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Helmet } from 'react-helmet';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import bgImage from "assets/images/usa.webp";

function ErrorPage() {
    return (
    <>
    <Helmet>
      <title>404 Not Found - TerraGrasp</title>
      <meta name="description" content="The page you're looking for doesn't exist. Explore TerraGrasp for US income data by zip code." />
    </Helmet>
    <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
        }}
    >
        <Container >
        <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" }}}
        >
            <MKTypography
                component="h1"
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                },
            })}
            >
                404 - Page Not Found
            </MKTypography>
            <MKTypography
                variant="body1"
                color="white"
                mt={1}
                pr={{ md: 12, lg: 24, xl: 32 }}
                opacity={0.8}
            >
                The page you are looking for does not exist. You might have mistyped the address or the page may have moved.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={6} mb={3}>
                <ReturnButton name={"Return to your previouse page"}/>
                <MKButton variant="text" color="white" component={Link} to='/'>
                    Return Home
                </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
      </MKBox>
    </>
)}

export default ErrorPage;

