import React, { useState } from 'react';
import { useCart } from 'react-use-cart';
import { ConvertPrice } from '../product/ProductUtils';
import ShoppingCartLineItem from './ShoppingCartLineItem';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const Cart = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const [error, setError] = useState(null);
    const {
        isEmpty,
        items,
        cartTotal,
    } = useCart();

    const userData = useAuthUser()

    const onCheckout = () => {
        const checkoutData = {
            "product_ids": items.map((item) => (item.id))
        }
        if (userData !== null) {
            checkoutData.email = userData.email;
        } else {
            checkoutData.email = "";
        }
        if (checkoutData["product_ids"].length > 0) {
        axios.post(`${process.env.REACT_APP_API_URL}/payments/create-checkout-session`, checkoutData)
        .then(response => {
            window.location.href = response.data.url
        })
        .catch(error => {
            setError(error.toString());
        });
    }
}
    return (
        <MKBox component="section" py={3}>
        <Container>
        <MKBox mt={2} mb={1}>
            <MKButton variant="gradient" color="light"  onClick={() => navigate("/income-by-zipcode/usa")}>
            Continue Shopping
            </MKButton>
        </MKBox>
            <Grid container item flexDirection="column" alignItems="center" xs={12} sx={{ textAlign: "center", mx: "auto" }} lg={6} >
                <MKTypography variant="h2" mb={1}>
                    Checkout{" "}
                </MKTypography>
                
            </Grid>
            <Grid container spacing={2} flexDirection="column" pt={2}>
                <Box sx={{ pt:{xs:8, md:3}, pb:{xs:8, md:3} }}>
                    <Grid container>
                        <Grid item xs={.2} md={.2}></Grid>
                        <Grid item xs={12} md={7.6}>
                <Box sx={{
                    height: 'auto',
                    overflowY: 'auto',
                    background: 'white',
                    padding: 1,
                    margin: 2, 
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isEmpty ? 'center' : 'flex-start', // Center the content vertically if empty
                    textAlign: 'center' // Center the text horizontally
                }}>
                    {isEmpty ?
                        <>
                        <Typography variant="body1" sx={{ maxWidth: '100%', margin: 'auto' }}>
                            There is currently nothing in your cart
                        </Typography>
                        <MKBox mt={1} mb={1}>
                            <MKButton variant="gradient" color="info"  to="/income-by-zipcode/usa" component={Link} >
                                Explore our Products
                            </MKButton>
                        </MKBox>
                        </>
                        :
                        items.map((item) => (
                            <ShoppingCartLineItem key={item.id} item={item} />
                        ))
                    }
                </Box>
            </Grid>
                <Grid item xs={.4} md={.4}></Grid>
                    <Grid item xs={12} md={3.6} >
                        <Box
                            sx={{
                                position: 'sticky',
                                top: { xs: 8, md: 10 },
                                minHeight: 300,
                                overflowY: 'auto',
                                background: 'white',
                                padding: 1,
                                margin: 2,
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '16px'
                            }} >
                            
                            <CardContent>
                                <Typography variant="h2" component="div" sx={{ borderBottom: '1px solid black' }}>
                                    Order Summary
                                </Typography>
                                <Grid container alignItems="center" sx={{ height: '80px' }}>
                                    <Grid item xs={12} md={12} lg={6} sx={{  textAlign: 'left' }}>
                                        <Typography variant="h5" component="div">
                                            Subtotal
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} sx={{ textAlign: 'right' }}>
                                        <Typography variant="h5" component="div">
                                            ${ConvertPrice(cartTotal)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="top" sx={{ height: '90px', borderBottom: '1px solid black' }}>
                                    <Grid item xs={5} md={12} lg={6} sx={{  textAlign: 'left' }}>
                                        <Typography variant="h5" component="div" fontSize={'20px'}>
                                            Estimated Tax
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} md={12} lg={6} sx={{ textAlign: 'right' }} >
                                        <Typography fontSize={'12px'} component="div" >
                                            Calculated at Checkout
                                        </Typography>
                                    </Grid>
                                </Grid>
                                    { isAuthenticated ?
                                        <Grid container alignItems="center" justifyContent='center'  item xs={12} md={12} sx={{  textAlign: 'center' }}>
                                            <MKBox mt={2} mb={1}>
                                                <MKButton variant="gradient" color="info"  onClick={() => onCheckout()}>
                                                    Checkout
                                                </MKButton>
                                            </MKBox>
                                        </Grid>
                                        :
                                        <>
                                            <Grid container justifyContent='center' item xs={12} md={12} sx={{  textAlign: 'center' }}>
                                                <MKBox mt={1} mb={1}>
                                                    <MKButton variant="gradient" color="info"  onClick={() => onCheckout()}>
                                                    Checkout As Guest
                                                    </MKButton>
                                                </MKBox>
                                            </Grid>
                                            <Grid container  justifyContent='center' item xs={12} md={12} sx={{  textAlign: 'center' }}>
                                                <MKBox mt={1} mb={1}>
                                                    <MKButton variant="gradient" color="warning"  to="/user/login-checkout" component={Link} >
                                                    Sign In and Checkout
                                                    </MKButton>
                                                </MKBox>
                                            </Grid>
                                        </>
                                    }
                            </CardContent>
                            </Box>
                        </Grid>
                        <Grid item xs={.2} md={.2}></Grid>
                    </Grid>
                </Box>
            </Grid>
        </Container>
        </MKBox>
    );
};

export default Cart;
