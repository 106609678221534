import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

const VerticalBarChart = ({ 
  data1, 
  data2, 
  colors, 
  label_text1,
  label_text2,
  title_text,
  currency = 'USD', 
  style = 'currency', 
  minimumFractionDigits = 0, 
  maximumFractionDigits = 1 
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);


  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      
      // Destroy existing chart if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      

      // Create new bar chart
      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: Object.keys(data1),
          datasets: [
            {
              label: label_text1,
              data: Object.values(data1),
              backgroundColor: colors[0] || '#3182CE',
              borderColor: 'black',
              borderWidth: 1,
              categoryPercentage: 1
            },
            {
              label: label_text2,
              data: Object.values(data2),
              backgroundColor: colors[1] || '#ED64A6',
              borderColor: 'black',
              borderWidth: 1,
              categoryPercentage: 1,
            }
          ]
        },
        options: {
          indexAxis: 'x', // Change this to 'x' for vertical bars
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: title_text,
              font: {
                size: 18 // Increased font size
              }
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-US', {
                      style,
                      currency,
                      minimumFractionDigits,
                      maximumFractionDigits
                    }).format(context.parsed.y);
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            x: {
              stacked: false,
              grid: {
                display: false
              }
            },
            y: {
              stacked: false,
              beginAtZero: true,
              grid: {
                display: false
              },
              ticks: {
                callback: function(value) {
                  return new Intl.NumberFormat('en-US', {
                    style,
                    currency,
                    minimumFractionDigits,
                    maximumFractionDigits
                  }).format(value);
                }
              }
            }
          },
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          }
        }
      });
    }
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data1, data2, colors, currency, style, minimumFractionDigits, maximumFractionDigits]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }}></canvas>
    </div>
  );
};

export default VerticalBarChart;
