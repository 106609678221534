import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';


const Pyramid = ({
  distribution_1,
  distribution_2,
  colors = {
    dist1: 'rgba(75, 192, 192, 0.8)',
    dist1Border: 'rgba(75, 192, 192, 1)',
    dist2: 'rgba(255, 206, 86, 0.8)',
    dist2Border: 'rgba(255, 206, 86, 1)',
  },
  label_text1 = 'Distribution 1',
  label_text2 = 'Distribution 2',
  title_text = 'Income Distribution Pyramid',
  currency = '$',
  pyramid_height = '500px'
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        
        // Combine the two distributions
        const labels = Object.keys(distribution_1);
        const data1 = Object.values(distribution_1);
        const data2 = Object.values(distribution_2);

        // Calculate the maximum value for both sides and round up to nearest 5%
        const maxValue = Math.ceil(Math.max(
          ...data1.map(Math.abs),
          ...data2.map(Math.abs)
        ) / 5) * 5;

        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: label_text1,
                data: data1.map(value => -value),
                backgroundColor: colors.dist1,
                borderColor: 'black',
                borderWidth: 1,
              },
              {
                label: label_text2,
                data: data2,
                backgroundColor: colors.dist2,
                borderColor: 'black',
                borderWidth: 1,
              }
            ]
          },
          options: {
            indexAxis: 'y',
            scales: {
              x: {
                stacked: true,
                min: -maxValue,
                max: maxValue,
                ticks: {
                  callback: function(value) {
                    return Math.abs(value) + '%';
                  }
                }
              },
              y: {
                stacked: true
              }
            },
            plugins: {
              title: {
                display: true,
                text: title_text,
                font: {
                  size: 18
                }
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.x !== null) {
                      label += Math.abs(context.parsed.x).toFixed(1) + '%';
                    }
                    return label;
                  }
                }
              }
            },
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }
        });
      }
    };

    createChart();

    return () => {
      // Destroy the chart when the component unmounts
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [distribution_1, distribution_2, colors, label_text1, label_text2, title_text, currency]);

  return (
    <div style={{  height: pyramid_height, position: 'relative' }}>
      <canvas ref={chartRef} ></canvas>
    </div>
    );
};

export default Pyramid;
