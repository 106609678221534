import React, { useState, useEffect, useRef, useMemo, createContext, useContext } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap, ZoomControl, Marker, Popup } from "react-leaflet";
import L, { divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { renderToStaticMarkup } from 'react-dom/server';
import TableRow from './TableRow';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Grid from '@mui/material/Grid';
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import mapColors from './map_colors.json';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-router-dom';
// Create a context for selected metrics
export const MetricsContext = createContext();


const StateMap = ({ initialState, initialMetrics, hierarchyType }) => {
  const [selectedState, setSelectedState] = useState(initialState || '');
  const [selectedMetrics, setSelectedMetrics] = useState(initialMetrics || []);
  const [MetricHierarchy, setMetricHierarchy] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [mapDefinitions, setMapDefinitions] = useState(null);
  const [error, setError] = useState(null);
  const [maxBounds, setMaxBounds] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const mapRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [tractGeoJson, setTractGeoJson] = useState(null);
  const [selectedTractId, setSelectedTractId] = useState(null);
  const [isLoadingTracts, setIsLoadingTracts] = useState(false);
  const [viewMode, setViewMode] = useState('counties'); // can be 'counties' or 'tracts'
  const [countyGeoJson, setCountyGeoJson] = useState(null);  // Store county data separately
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [showMetricsSelection, setShowMetricsSelection] = useState(false);
  const [metricsModified, setMetricsModified] = useState(false);
  const [primaryMetric, setPrimaryMetric] = useState(null);
  const [opacitySelected, setOpacitySelected] = useState(0.9);
  const [opacityUnselected, setOpacityUnselected] = useState(0.8);
  // const opacityColorGradient = 0.3;
  const lineColor = ['#FF0000', '#000000']
  const [selectedColorGradient, setSelectedColorGradient] = useState(Object.keys(mapColors.list_of_9)[0] || 'red_to_green');
  const [opacityColorGradient, setOpacityColorGradient] = useState(0.3);
  const [isColorControlsCollapsed, setIsColorControlsCollapsed] = useState(false);


  useEffect(() => {
    const fetchMapDefinitions = async () => {
      try {
        const definitionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state_map_definitions`);
        setMapDefinitions(definitionsResponse.data);

        if (selectedState) {  // Only fetch if we have a selectedState
          const geoJsonResponse = await axios.get(`${process.env.REACT_APP_API_URL}/geometries/counties/${selectedState}`);
          setCountyGeoJson(geoJsonResponse.data);
          setGeoJsonData(geoJsonResponse.data);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMapDefinitions();
  }, [selectedState]);

  useEffect(() => {
    const fetchHierarchyData = async () => {
      if (hierarchyType) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/utils/metric-hierarchies/${hierarchyType}`
          );
          setMetricHierarchy(response.data.categories);
        } catch (error) {
          console.error('Error fetching hierarchy data:', error);
        }
      }
    };

    fetchHierarchyData();
  }, [hierarchyType]);

  useEffect(() => {
    if (mapDefinitions && selectedState) {
      
      const state = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      const newMaxBounds = L.latLngBounds(
        [state.max_south-10, state.max_west-10],
        [state.max_north+10, state.max_east+10]
      );
      setMaxBounds(newMaxBounds);
    } else {
      // Default bounds for the entire US
      const defaultBounds = L.latLngBounds(
        [18.905547-2, -195.000000+2], // Southwest coordinates
        [71.341324+2, -66.934570+2]   // Northeast coordinates
      );
      setMaxBounds(defaultBounds);
    }
  }, [mapDefinitions, selectedState]);

  // Use useMemo to compute initialCenter
  const initialCenter = useMemo(() => {
    if (selectedState && mapDefinitions ) {
      const def = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      return [
        def.centroid_lat,
        def.centroid_long
      ];
    }
    return [39.8283, -98.5795]; // Default center of US
  }, [mapDefinitions, selectedState]);

  const initialZoom = 7;
  const minZoom =  2.5;
  const maxZoom =  20;

  // Add this useEffect hook
  useEffect(() => {
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 100); // Delay to allow for CSS transition
    }
  }, [isPanelVisible]);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const style = (feature) => {
    const isSelected = selectedFeatureId === feature.properties.GEOID;
    
    return {
      fillColor: isSelected ? '#ff4444' : '#0000ff',
      weight: isSelected ? 3 : 2,
      opacity: opacityColorGradient,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: (e) => {
        setSelectedFeatureId(feature.properties.GEOID);
        setSelectedFeature(feature);
        layer.setStyle(style(feature));
      },
    });
  };

  // Helper function to get popup position
  const getPopupPosition = (feature) => {
    // Try to get coordinates from different possible properties
    if (feature.properties.centroid_lat && feature.properties.centroid_long) {
      return [feature.properties.centroid_lat, feature.properties.centroid_long];
    }
    
    // Fallback: try to calculate center of the feature
    if (feature.geometry) {
      try {
        const bounds = L.geoJSON(feature).getBounds();
        return bounds.getCenter();
      } catch (error) {
        console.error('Error calculating feature center:', error);
      }
    }
    
    // Final fallback: use map center
    return initialCenter;
  };

  const handleViewTracts = async (geoid) => {
    try {
      setIsLoadingTracts(true);
      setSelectedCounty(selectedFeature);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/geometries/tracts_with_metrics/${selectedState}/${geoid}`,
        {
          Metric_ids: selectedMetrics.flatMap(metric => metric.item_mapping)
        }
      );

      if (primaryMetric) {
        colorTractsByMetric(response.data, primaryMetric);
      }
      
      setTractGeoJson(response.data);
      setGeoJsonData(null);
      setSelectedFeature(null);
      setViewMode('tracts');
      
      if (response.data.bounds && 
          response.data.bounds.centroid_lat && 
          response.data.bounds.centroid_long) {
        mapRef.current.flyTo(
          [response.data.bounds.centroid_lat, response.data.bounds.centroid_long],
          10,
          {
            duration: 2.5,
            easeLinearity: 0.25
          }
        );
      }

    } catch (error) {
      console.error('Error fetching tract data:', error);
    } finally {
      setIsLoadingTracts(false);
    }
  };

  // Update the tractStyle function
  const tractStyle = (feature) => {
    if (!primaryMetric) {
      // Default style when no primary metric is selected
      const isSelected = selectedTractId === feature.properties.GEOID;
      return {
        fillColor: isSelected ? '#ff4444' : '#FFA500',
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    // Get the metric value from the feature properties
    const metricValue = feature.properties.metrics?.[primaryMetric.item_mapping[0]];
    const isSelected = selectedTractId === feature.properties.GEOID;
    
    if (metricValue === undefined || metricValue === null) {
      return {
        fillColor: '#ff0000',
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    if (Number(metricValue) < 0) {
      return {
        fillColor: '#808080',
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    const allValues = tractGeoJson.features
      .map(f => f.properties.metrics?.[primaryMetric.item_mapping[0]])
      .filter(v => v !== undefined && v !== null && Number(v) >= 0)
      .map(Number);
    
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    const percentage = (Number(metricValue) - min) / (max - min);
    
    // Get the color gradient array
    const colors = mapColors.list_of_9[selectedColorGradient];
    
    // Calculate the index in the color array (0-8)
    const colorIndex = Math.min(Math.floor(percentage * 9), 8);
    const color = colors[colorIndex];

    return {
      fillColor: color,
      weight: isSelected ? 3 : 2,
      opacity: opacityColorGradient,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  };

  const onEachTract = (feature, layer) => {
    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e); // Stop the event from bubbling up to the map
        setSelectedTractId(feature.properties.GEOID);
        setSelectedFeature(feature);
        layer.setStyle(tractStyle(feature));
      },
    });
  };

  // You might want to add a function to go back to counties view
  const handleBackToCounties = () => {
    setTractGeoJson(null);
    setSelectedTractId(null);
    setViewMode('counties');
    setGeoJsonData(countyGeoJson);  // Restore the county data
    setSelectedFeature(null);
    
    // Reset map view with animation
    if (mapRef.current) {
      mapRef.current.flyTo(initialCenter, initialZoom, {
        duration: 1.5,
        easeLinearity: 0.25
      });
    }
  };

  // Helper function to get all children for an item
  const getChildren = (itemId) => {
    return Object.values(MetricHierarchy).filter(item => item.parent === itemId);
  };

  // Add this function to handle metric changes
  const handleMetricChange = (newMetrics) => {
    if (Array.isArray(newMetrics)) {
      setSelectedMetrics(newMetrics);
    } else {
      // If it's a function, pass it through
      setSelectedMetrics(newMetrics);
    }
    setMetricsModified(true);
  };

  // Add this function to handle reload
  const handleReload = async () => {
    try {
      // Reset to county view
      setTractGeoJson(null);
      setSelectedTractId(null);
      setViewMode('counties');
      setGeoJsonData(countyGeoJson);
      setSelectedFeature(null);
      setSelectedFeatureId(null);
      setMetricsModified(false);
      
      // Reset map view
      if (mapRef.current) {
        mapRef.current.setView(initialCenter, initialZoom);
      }
    } catch (error) {
      console.error('Error reloading map:', error);
    }
  };

  const handleSetPrimaryMetric = (metric) => {
    setPrimaryMetric(metric);
    if (viewMode === 'tracts' && tractGeoJson) {
      colorTractsByMetric(tractGeoJson, metric);
    }
  };

  const colorTractsByMetric = (geoJson, metric) => {
    if (!geoJson || !metric) return;

    // Get all values for this metric
    const values = geoJson.features
      .map(f => f.properties.metrics?.[metric.item_mapping[0]])
      .filter(v => v !== undefined && v !== null)
      .map(Number);

    const min = Math.min(...values);
    const max = Math.max(...values);

    // Update tract style function
    const tractStyle = (feature) => {
      const isSelected = selectedTractId === feature.properties.GEOID;
      const metricValue = feature.properties.metrics?.[metric.item_mapping[0]];
      
      if (metricValue === undefined || metricValue === null) {
        return {
          fillColor: '#gray',
          weight: isSelected ? 3 : 2,
          opacity: opacityColorGradient,
          color: isSelected ? lineColor[0] : lineColor[1],
          dashArray: '3',
          fillOpacity: isSelected ? opacitySelected : opacityUnselected
        };
      }

      // Calculate color on a scale from red to green
      const percentage = (metricValue - min) / (max - min);
      const hue = percentage * 120; // 0 is red, 120 is green
      const color = `hsl(${hue}, 75%, 50%)`;

      return {
        fillColor: color,
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    };

    // Update the GeoJSON style
    setTractGeoJson({
      ...geoJson,
      features: geoJson.features.map(feature => ({
        ...feature,
        style: tractStyle(feature)
      }))
    });
  };

  const formatMetricValue = (value, metricId) => {
    if (Number(value) < 0) return '(X)';
    
    // Convert to number for formatting
    const numValue = Number(value);
    
    // Format based on metric type
    if (metricId.endsWith('PE') || metricId.endsWith('PM')) {
      // Format as percentage with 1 decimal place
      return `${numValue.toFixed(1)}%`;
    } else if (metricId.endsWith('E') || metricId.endsWith('M')) {
      // Format as number with comma separators
      return numValue.toLocaleString();
    }
    
    // Default formatting if no specific type matches
    return value;
  };

  // Add a click handler for the map
  const MapClickHandler = () => {
    const map = useMap();
    
    useEffect(() => {
      const handleMapClick = (e) => {
        // Check if click was on a tract (handled by onEachFeature)
        // If not, clear the selection
        if (viewMode === 'tracts') {
          setSelectedTractId(null);
          setSelectedFeature(null);
        }
      };

      map.on('click', handleMapClick);

      return () => {
        map.off('click', handleMapClick);
      };
    }, [viewMode]);

    return null;
  };

  // Add this helper function to group metrics
  const groupMetrics = (metrics) => {
    const groups = {};
    
    Object.entries(metrics).forEach(([metricId, value]) => {
      // Find the base name by removing the suffix (E, M, PE, PM)
      const baseName = metricId.replace(/[EMP]+$/, '');
      
      if (!groups[baseName]) {
        // Find the metric from selectedMetrics that matches this base ID
        const metric = selectedMetrics.find(m => 
          m.item_mapping.some(id => id.startsWith(baseName))
        );
        
        groups[baseName] = {
          label: metric?.label || baseName, // Use the label from selectedMetrics
          values: {}
        };
      }
      
      if (metricId.endsWith('PE')) groups[baseName].values.PE = value;
      else if (metricId.endsWith('PM')) groups[baseName].values.PM = value;
      else if (metricId.endsWith('E') && !metricId.endsWith('PE')) groups[baseName].values.E = value;
      else if (metricId.endsWith('M') && !metricId.endsWith('PM')) groups[baseName].values.M = value;
    });
    
    return groups;
  };

  // Add this after the Selected Metrics section in the render
  const colorGradientSelector = (
    <div style={{ 
      marginTop: '20px',
      padding: '15px',
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
      border: '1px solid #ddd'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        marginBottom: '10px'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormatColorFillIcon 
            sx={{ 
              marginRight: '8px',
              color: '#666'
            }} 
          />
          <h3 style={{ margin: 0, fontSize: '18px' }}>Color Controls</h3>
        </div>
        <Tooltip title={isColorControlsCollapsed ? "Expand" : "Collapse"}>
          <KeyboardArrowRightIcon 
            sx={{ 
              cursor: 'pointer',
              transform: isColorControlsCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.3s ease'
            }}
            onClick={() => setIsColorControlsCollapsed(!isColorControlsCollapsed)}
          />
        </Tooltip>
      </div>
      
      <div style={{
        maxHeight: isColorControlsCollapsed ? '0' : '2000px',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        opacity: isColorControlsCollapsed ? 0 : 1
      }}>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 0 }}>
          <InputLabel id="color-gradient-select-label">Color Selection</InputLabel>
          <Select
            labelId="color-gradient-select-label"
            id="color-gradient-select"
            value={selectedColorGradient}
            label="Color Selection"
            onChange={(e) => setSelectedColorGradient(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 500,
                  padding: '10px',
                  paddingTop: '20px'
                },
              },
            }}
          >
            {Object.keys(mapColors.list_of_9).map((gradientKey) => (
              <MenuItem 
                key={gradientKey} 
                value={gradientKey}
                style={{
                  height: '80px',
                  padding: '1px',
                  marginBottom: '2px'
                }}
              >
                <div style={{ 
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1px'
                }}>
                  <span style={{ 
                    fontSize: '16px',
                    color: '#333',
                    fontWeight: '500',
                    marginTop: '8px',
                    marginBottom: '2px'
                  }}>
                    {gradientKey.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </span>
                  <div style={{ 
                    display: 'flex', 
                    width: '100%',
                    height: '30px',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                    marginBottom: '6px'
                  }}>
                    {mapColors.list_of_9[gradientKey].map((color, index) => (
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          backgroundColor: color
                        }}
                      />
                    ))}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

          <div style={{ 
            marginTop: '3px',
            backgroundColor: '#f5f5f5',
            borderRadius: '5px',
            fontSize: '14px'
          }}>
            <div>
              <div style={{ marginBottom: '5px' }}>
                <strong>Color Opacity: </strong>
                {(opacityUnselected * 100).toFixed(0)}%
              </div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={opacityUnselected}
                onChange={(e) => setOpacityUnselected(parseFloat(e.target.value))}
                style={{
                  width: '100%',
                  height: '8px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  appearance: 'none',
                  backgroundColor: '#ddd',
                  outline: 'none',
                }}
              />
            </div>

            <div style={{ marginBottom: '5px' }}>
              <div style={{ marginBottom: '5px' }}>
                <strong>Selected Feature: </strong>
                {(opacitySelected * 100).toFixed(0)}%
              </div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={opacitySelected}
                onChange={(e) => setOpacitySelected(parseFloat(e.target.value))}
                style={{
                  width: '100%',
                  height: '8px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  appearance: 'none',
                  backgroundColor: '#ddd',
                  outline: 'none',
                }}
              />
            </div>

            <div style={{ marginBottom: '5px' }}>
              <div style={{ marginBottom: '5px' }}>
                <strong>Border Gradient: </strong>
                {(opacityColorGradient * 100).toFixed(0)}%
              </div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={opacityColorGradient}
                onChange={(e) => setOpacityColorGradient(parseFloat(e.target.value))}
                style={{
                  width: '100%',
                  height: '8px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  appearance: 'none',
                  backgroundColor: '#ddd',
                  outline: 'none',
                }}
              />
            </div>
          </div>
      </div>
    </div>
  );

  return (
    <div style={{
      position: "relative",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      margin: 0,
      padding: 0
    }}>
      <div style={{
        position: "absolute",
        top: "10px",
        left: isPanelVisible ? "410px" : "10px",
        right: "70px",
        backgroundColor: "white",
        padding: "8px 16px",
        zIndex: 999,
        border: "1px solid #ddd",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        height: "48px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        transition: "left 0.3s ease-in-out"
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '4px'
        }}>
          <button style={{
            padding: '4px 12px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <span><strong>State:</strong> {' ' +selectedState?.toUpperCase() || 'State'}</span>
          </button>

          <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />

          <button style={{
            padding: '4px 12px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            opacity: selectedCounty || (viewMode === 'counties' && selectedFeature) ? 1 : 0.5,
          }}
          onClick={() => handleBackToCounties()}
          >
            {viewMode === 'counties' && selectedFeature ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +selectedFeature.properties.NAMELSAD}</span>
              </>
            ) : viewMode === 'tracts' && selectedCounty ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +selectedCounty.properties.NAMELSAD}</span>
              </>
            ) : (
              'County'
            )}
          </button>

          {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
            <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
          )}

          {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: viewMode === 'counties' && selectedFeature ? 'pointer' : 'default',
              fontSize: '14px',
              opacity: viewMode === 'tracts' ? 1 : 0.5,
            }}
            onClick={() => {
              if (viewMode === 'counties' && selectedFeature) {
                handleViewTracts(selectedFeature.properties.GEOID);
              }
            }}
            >
              {viewMode === 'tracts' && selectedFeature ? (
                <>
                  <strong>Tract:</strong> 
                  <span>{' ' +selectedFeature.properties.GEOIDFQ}</span>
                </>
              ) : (
                <>
                  <strong>Tract:</strong> 
                </>
              )}
            </button>
          )}

          {primaryMetric && (
            <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
          )}

          {primaryMetric && (
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'default',
              fontSize: '14px',
            }}>
              <strong>Metric:</strong> <span>{' ' +primaryMetric.label}</span>
            </button>
          )}
        </div>
      </div>

      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}>
        {maxBounds && (
          <MapContainer 
            key={`${selectedState}-${initialCenter[0]}-${initialCenter[1]}`}
            center={initialCenter}
            zoom={initialZoom} 
            maxBounds={maxBounds}
            maxBoundsViscosity={1.0}
            minZoom={minZoom}
            maxZoom={maxZoom}
            style={{
              width: "100%",
              height: "100%"
            }}
            ref={mapRef}
            zoomControl={false}
            attributionControl={false}
          >
            <ZoomControl position="topright" />
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=8f074345-7c64-4b84-bedc-a74592e223c5`}
              minZoom={0}
              maxZoom={20}
            />
            {/* County boundaries */}
            {viewMode === 'counties' && geoJsonData && (
              <GeoJSON 
                key={selectedFeatureId}
                data={geoJsonData} 
                style={style}
                onEachFeature={onEachFeature}
              />
            )}
            
            {/* Tract boundaries */}
            {viewMode === 'tracts' && tractGeoJson && (
              <GeoJSON 
                key={`tracts-${selectedTractId}-${primaryMetric?.id}`}
                data={tractGeoJson}
                style={tractStyle}
                onEachFeature={onEachTract}
              />
            )}
            {selectedFeature && (
              <Popup
                position={getPopupPosition(selectedFeature)}
              >
                <div>
                  <h3 style={{ margin: '0 0 10px 0', fontSize: '1.2em' }}>
                    {viewMode === 'counties' 
                      ? selectedFeature.properties.NAMELSAD 
                      : `Tract ${selectedFeature.properties.GEOIDFQ}`
                    }
                  </h3>
                  
                  <div style={{ marginTop: '10px' }}>
                    <p style={{ margin: '5px 0' }}>
                      <strong>Area:</strong> {(selectedFeature.properties.ALAND / 2589988).toFixed(0)} sq mi
                    </p>
                    <p style={{ margin: '5px 0' }}>
                      <strong>Water Area:</strong> {(selectedFeature.properties.AWATER / 2589988).toFixed(1)} sq mi
                    </p>

                    {/* Grouped metrics */}
                    {viewMode === 'tracts' && selectedFeature.properties.metrics && (
                      <div style={{ 
                        marginTop: '15px',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        borderTop: '1px solid #ddd',
                        paddingTop: '10px'
                      }}>
                        <table style={{ 
                          width: '100%',
                          borderCollapse: 'collapse',
                          marginTop: '10px'
                        }}>
                          <thead>
                            <tr>
                              <th style={{ 
                                textAlign: 'left', 
                                padding: '8px',
                                borderBottom: '2px solid #ddd',
                                backgroundColor: '#f8f9fa'
                              }}>Metric</th>
                              <th style={{ 
                                textAlign: 'right', 
                                padding: '8px',
                                borderBottom: '2px solid #ddd',
                                backgroundColor: '#f8f9fa'
                              }}>Estimate</th>
                              <th style={{ 
                                textAlign: 'right', 
                                padding: '8px',
                                borderBottom: '2px solid #ddd',
                                backgroundColor: '#f8f9fa'
                              }}>Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(groupMetrics(selectedFeature.properties.metrics)).map(([baseId, group]) => (
                              <tr key={baseId} style={{ borderBottom: '1px solid #ddd' }}>
                                <td style={{ padding: '8px', fontWeight: 'bold' }}>{group.label}</td>
                                <td style={{ padding: '8px', textAlign: 'right' }}>
                                  {group.values.E !== undefined ? formatMetricValue(group.values.E, baseId + 'E') : '-'}
                                  {group.values.M !== undefined && (
                                    <div style={{ fontSize: '0.8em', color: '#666' }}>
                                      ±{formatMetricValue(group.values.M, baseId + 'M')}
                                    </div>
                                  )}
                                </td>
                                <td style={{ padding: '8px', textAlign: 'right' }}>
                                  {group.values.PE !== undefined ? formatMetricValue(group.values.PE, baseId + 'PE') : '-'}
                                  {group.values.PM !== undefined && (
                                    <div style={{ fontSize: '0.8em', color: '#666' }}>
                                      ±{formatMetricValue(group.values.PM, baseId + 'PM')}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  {viewMode === 'counties' && (
                    <button
                      onClick={() => handleViewTracts(selectedFeature.properties.GEOID)}
                      disabled={isLoadingTracts}
                      style={{
                        marginTop: '10px',
                        padding: '8px 15px',
                        backgroundColor: isLoadingTracts ? '#cccccc' : '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: isLoadingTracts ? 'not-allowed' : 'pointer',
                        width: '100%',
                        fontWeight: 'bold'
                      }}
                    >
                      {isLoadingTracts ? 'Loading Tracts...' : 'View Tracts'}
                    </button>
                  )}
                </div>
              </Popup>
            )}
            <MapClickHandler />
          </MapContainer>
        )}
        {error && <div style={{ position: 'absolute', zIndex: 2, backgroundColor: 'white', padding: '10px' }}>Error: {error}</div>}
      </div>

      <div style={{
        position: "absolute",
        left: isPanelVisible ? "0" : "-400px",
        top: "0",
        height: "100%",
        width: "400px",
        backgroundColor: "white",
        padding: "20px",
        boxSizing: "border-box",
        overflowY: "auto",
        border: '1px solid #ccc',
        borderRadius: '0 5px 5px 0',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: "left 0.3s ease-in-out",
        zIndex: 1000
      }}>
        <button
          onClick={togglePanelVisibility}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '5px 10px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Hide Panel
        </button>

        <h2>Control Panel</h2>

        {/* Metrics Selection Button */}
        <button
          onClick={() => setShowMetricsSelection(!showMetricsSelection)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '15px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span>Available Metrics</span>
          <span>{showMetricsSelection ? '▲' : '▼'}</span>
        </button>

        {/* Available Metrics Section */}
        {showMetricsSelection && MetricHierarchy && (
          <div style={{ 
            marginBottom: '20px',
            padding: '15px',
            backgroundColor: '#f5f5f5',
            borderRadius: '5px',
            border: '1px solid #ddd'
          }}>
            <div style={{ marginTop: '10px' }}>
              <div style={{ 
                maxHeight: '300px', 
                overflowY: 'auto',
                border: '1px solid #ddd',
                borderRadius: '4px'
              }}>
                <table style={{ 
                  width: '100%',
                  borderCollapse: 'collapse',
                  backgroundColor: 'white'
                }}>
                  <tbody>
                    {Object.values(MetricHierarchy)
                      .filter(item => !item.parent)
                      .map(item => (
                        <TableRow 
                          key={item.id} 
                          item={item}
                          expandedCategories={expandedCategories}
                          setExpandedCategories={setExpandedCategories}
                          setSelectedMetrics={handleMetricChange}
                          getChildren={getChildren}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* Add this after the Selected Metrics section */}
        {metricsModified && (
          <div style={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#fff3cd',
            borderRadius: '5px',
            border: '1px solid #ffeeba'
          }}>
            <p style={{
              color: '#856404',
              margin: '0 0 10px 0'
            }}>
              Metrics have been modified. Reload to apply changes.
            </p>
            <button
              onClick={handleReload}
              style={{
                width: '100%',
                padding: '8px 15px',
                backgroundColor: '#17a2b8',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >
              Reload Map
            </button>
          </div>
        )}
        {/* Selected Metrics Section */}
        <div style={{ 
          marginTop: '20px',
          padding: '15px',
          backgroundColor: '#f5f5f5',
          borderRadius: '5px',
          border: '1px solid #ddd'
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            marginBottom: '10px'
          }}>
            <TuneIcon 
              sx={{ 
                marginRight: '8px',
                color: '#666'
              }} 
            />
            <h3 style={{ margin: 0, fontSize: '18px' }}>Selected Metrics</h3>
          </div>
          
          {selectedMetrics.length === 0 ? (
            <p>No metrics selected</p>
          ) : (
            <div>
              {selectedMetrics.map(metric => (
                <Grid container spacing={0} key={metric.id} style={{ marginBottom: '8px' }}>
                  <Grid item xs={9}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      borderRadius: '4px 0 0 4px',  // Round only left corners
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderRight: 'none'  // Remove right border
                    }}>
                      <span style={{ fontSize: '16px' }}>{metric.label}</span>
                    </div>
                  </Grid>
                  <Grid item xs={1.5}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderLeft: 'none',  // Remove left border
                      borderRight: 'none'  // Remove right border
                    }}>
                      <Tooltip title={primaryMetric?.id === metric.id ? 'Primary Metric' : 'Set as Primary Metric'} arrow placement="top">
                        {primaryMetric?.id === metric.id ? (
                          <RadioButtonCheckedIcon 
                            fontSize="medium"
                            onClick={() => handleSetPrimaryMetric(metric)}
                            sx={{
                              cursor: 'pointer',
                              color: '#2196f3',
                              '&:hover': {
                                backgroundColor: 'rgba(33, 150, 243, 0.1)'
                              }
                            }}
                          />
                        ) : (
                          <FormatColorFillIcon 
                            fontSize="medium"
                            onClick={() => handleSetPrimaryMetric(metric)}
                            sx={{
                              cursor: 'pointer',
                              color: '#4CAF50',
                              '&:hover': {
                                backgroundColor: 'rgba(76, 175, 80, 0.1)'
                              }
                            }}
                          />
                        )}
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={1.5}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      borderRadius: '0 4px 4px 0',  // Round only right corners
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderLeft: 'none'  // Remove left border
                    }}>
                      <Tooltip title="Remove Metric" arrow placement="top">
                        <CloseIcon 
                          fontSize="medium"
                          onClick={() => handleMetricChange(prev => 
                            prev.filter(m => m.id !== metric.id)
                          )}
                          sx={{
                            cursor: 'pointer',
                            color: '#f44336',
                            '&:hover': {
                              backgroundColor: 'rgba(244, 67, 54, 0.1)'
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        </div>

        {colorGradientSelector}

        {/* Back to Counties button (only show when viewing tracts) */}
        {viewMode === 'tracts' && (
          <button
            onClick={handleBackToCounties}
            style={{
              marginTop: '10px',
              padding: '8px 15px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              width: '100%'
            }}
          >
            Back to Counties
          </button>
        )}
      </div>

      {/* Show Panel button - Adjust top position */}
      <button
        onClick={togglePanelVisibility}
        style={{
          position: 'absolute',
          top: '58px', // Adjust to account for LocationBar
          left: isPanelVisible ? '-200px' : '10px',
          zIndex: 1001,
          padding: '5px 10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: "left 0.3s ease-in-out"
        }}
      >
        Show Panel
      </button>

      {/* Add this button before the closing div */}
      <Link
        to="/account"
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          zIndex: 1001,
          padding: '8px 16px',
          backgroundColor: '#2196f3',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'background-color 0.3s ease',
          fontSize: '14px',
          fontWeight: 'bold',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#1976d2'
          }
        }}
      >
        Return Home
      </Link>

    </div>
  );
};

// Add prop types for type checking
StateMap.propTypes = {
  initialState: PropTypes.string,
  initialMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      item_mapping: PropTypes.array
    })
  ),
  hierarchyType: PropTypes.string
};

export default StateMap;
