import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StateSVGComponent from 'components/images/StateSVGs';
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const StateDropDown = (props) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleDropdownChange = (event, value) => {
        const selectedProduct = value.product_name;
        setSelectedValue(selectedProduct);
        props.onSelectButton(selectedProduct);
    };

    return (
        <>
            <Autocomplete
                id="country-select-demo"
                sx={{ width: "100%" }}
                options={props.products}
                autoHighlight
                disableClearable
                getOptionLabel={(option) => option.product_name}
                onChange={(event, value) => handleDropdownChange(event, value)}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <Box
                            key={key}
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                        >
                            <Grid container>
                                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                    <StateSVGComponent stateName={option.product_name} size={25} color={"#4681E4"} />
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                                    {option.product_name.replace(/_/g, ' ')} ${option.price / 100}
                                </Grid>
                            </Grid>
                        </Box>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.initialSet ? props.initialSet : "Choose a State"}
                        defaultValue={props.initialSet || ""}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </>
    );
};

export default StateDropDown;
