import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

const DonutChart = ({ chartData, colors }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const defaultColors = [
    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
    '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'
  ];

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      
      // Destroy existing chart if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: Object.keys(chartData.data),
          datasets: [{
            data: Object.values(chartData.data),
            backgroundColor: colors || defaultColors,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 5,
                font: {
                  size: 10
                }
              }
            },
            title: {
              display: true,
              text: chartData.title || 'Income Distribution',
              font: {
                size: 14
              },
              padding: {
                top: 0,
                bottom: 10
              }
            }
          },
          layout: {
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }
          }
        }
      });
    }

    // Cleanup function to destroy chart when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData, colors]);

  return <canvas ref={chartRef}></canvas>;
};

export default DonutChart;
