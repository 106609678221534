import React, {useEffect} from 'react';
import AuthProvider from 'react-auth-kit';
import RoutesComponent from './RoutesComponent';
import createStore from 'react-auth-kit/createStore';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

function App() {
  useEffect(() => {

    const handleRouteChange = (location) => {
      window.dataLayer.push({
        event: 'pageview',
        page: location.pathname
      });
    };

    window.addEventListener('popstate', () => handleRouteChange(window.location));

    return () => {
      window.removeEventListener('popstate', () => handleRouteChange(window.location));
    };
  }, []);

  const store_type = createStore({
    authName:'_auth',
    authType:'localstorage',
   })
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider store={store_type} >
        <CssBaseline />
        <RoutesComponent/>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;