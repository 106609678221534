import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StateMap from './StateMap';
import TableRow from './TableRow';
import { Autocomplete, TextField, Box, Grid, TableCell, TableRow as MuiTableRow, Button } from '@mui/material';
import StateSVGComponent from 'components/images/StateSVGs';

// Update the hierarchy options with a mapping object
const hierarchyOptions = [
  { label: 'Social', value: 'DP02' },
  { label: 'Economic', value: 'DP03' },
  { label: 'Housing', value: 'DP04' },
  { label: 'Demographics', value: 'DP05' }
];
const State_list = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'District Of Columbia', 'Florida', 'Georgia', 'Hawaii',
        'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
        'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
        'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
        'Wisconsin', 'Wyoming'

]
// Add this new component for displaying selected metrics
const SelectedMetricsList = ({ selectedMetrics, setSelectedMetrics }) => {
  const handleRemoveMetric = (metricId) => {
    setSelectedMetrics(prev => prev.filter(metric => metric.id !== metricId));
  };

  return (
    <div style={{ marginTop: '20px', width: '100%' }}>
      <h4>Selected Metrics:</h4>
      <div style={{
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: 'white',
        overflow: 'hidden',
        width: '100%'
      }}>
        {selectedMetrics.length === 0 ? (
          <div style={{ padding: '10px', color: '#666' }}>
            No metrics selected
          </div>
        ) : (
          selectedMetrics.map((metric, index) => (
            <MuiTableRow
              key={metric.id}
              sx={{ 
                cursor: 'default',
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                '&:hover': {
                  backgroundColor: '#e3f2fd',
                },
                height: '28px',
              }}
            >
              <TableCell
                sx={{
                  py: 0.25,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  width: '100%',
                  padding: '4px 16px',
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  color: '#1a1a1a',
                  flexGrow: 1,
                }}>
                  {metric.label}
                </div>
                <Button
                  onClick={() => handleRemoveMetric(metric.id)}
                  variant="contained"
                  size="small"
                  sx={{
                    minWidth: '80px',
                    fontSize: '.8rem',
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'none',
                    backgroundColor: '#d32f2f',
                    color: '#ffffff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#b71c1c',
                    },
                    '&:active': {
                      backgroundColor: '#b71c1c',
                    },
                  }}
                >
                  Remove
                </Button>
              </TableCell>
            </MuiTableRow>
          ))
        )}
      </div>
    </div>
  );
};

const MapSetUp = () => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedHierarchy, setSelectedHierarchy] = useState('');
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [isConfigured, setIsConfigured] = useState(false);
  const [hierarchyData, setHierarchyData] = useState(null);
  const [availableStates] = useState(State_list); // Add more states as needed
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  // Fetch hierarchy data when a hierarchy is selected
  useEffect(() => {
    const fetchHierarchyData = async () => {
      if (selectedHierarchy) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/utils/metric-hierarchies/${selectedHierarchy}`
          );
          setHierarchyData(response.data.categories);
        } catch (error) {
          console.error('Error fetching hierarchy data:', error);
        }
      }
    };

    fetchHierarchyData();
  }, [selectedHierarchy]);

  const handleStartMapping = () => {
    if (selectedState && selectedHierarchy && selectedMetrics.length > 0) {
      setIsConfigured(true);
    }
  };

  const getChildren = (itemId) => {
    return Object.values(hierarchyData).filter(item => item.parent === itemId);
  };

  if (isConfigured) {
    return (
      <StateMap 
        initialState={selectedState} 
        initialMetrics={selectedMetrics}
        hierarchyType={selectedHierarchy}
      />
    );
  }

  return (
    <div style={{
      maxWidth: '1200px',
      margin: '40px auto',
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <h2 style={{ marginBottom: '30px' }}>Map Configuration</h2>

      {/* State Selection - Updated */}
      <div style={{ marginBottom: '30px' }}>
        <h3>Select State</h3>
        <Autocomplete
          id="state-select"
          sx={{ width: "100%" }}
          options={availableStates.map(state => ({ product_name: state, price: 0 }))}
          autoHighlight
          disableClearable
          getOptionLabel={(option) => option.product_name}
          onChange={(event, value) => setSelectedState(value.product_name)}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ 
                  '& > img': { mr: 2, flexShrink: 0 },
                  padding: '4px 8px !important'
                }}
                {...optionProps}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={2} sx={{ textAlign: 'center' }}>
                    <StateSVGComponent stateName={option.product_name} size={35} color={"#4681E4"} />
                  </Grid>
                  <Grid item xs={9} sx={{ textAlign: 'left' }}>
                    {option.product_name.replace(/_/g, ' ')}
                  </Grid>
                </Grid>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedState || "Choose a State"}
              defaultValue={selectedState || ""}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </div>

      {/* Updated Hierarchy Selection */}
      <div style={{ marginBottom: '30px' }}>
        <h3>Select Metric Type</h3>
        <select 
          value={selectedHierarchy}
          onChange={(e) => {
            setSelectedHierarchy(e.target.value);
            setSelectedMetrics([]); // Reset selected metrics when hierarchy changes
          }}
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }}
        >
          <option value="">Select a metric type...</option>
          {hierarchyOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Metrics Selection Grid Layout */}
      {hierarchyData ? (
        <Grid container spacing={3} style={{ marginBottom: '30px' }}>
          <Grid item xs={8}>
            <h3>Select Initial Metrics</h3>
            <div style={{ 
              padding: '15px',
              backgroundColor: '#f5f5f5',
              borderRadius: '5px',
              border: '1px solid #ddd'
            }}>
              <div style={{ marginTop: '10px' }}>
                <div style={{ 
                  maxHeight: '300px', 
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  borderRadius: '4px'
                }}>
                  <table style={{ 
                    width: '100%',
                    borderCollapse: 'collapse',
                    backgroundColor: 'white'
                  }}>
                    <tbody>
                      {Object.values(hierarchyData)
                        .filter(item => !item.parent)
                        .map(item => (
                          <TableRow 
                            key={item.id} 
                            item={item}
                            expandedCategories={expandedCategories}
                            setExpandedCategories={setExpandedCategories}
                            setSelectedMetrics={setSelectedMetrics}
                            getChildren={getChildren}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Grid>
          
          <Grid item xs={4}>
            <SelectedMetricsList 
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
            />
          </Grid>
        </Grid>
      ) : (
        selectedHierarchy && (
          <div style={{ marginBottom: '30px' }}>
            <p>Loading metrics...</p>
          </div>
        )
      )}

      {/* Start Button */}
      <button
        onClick={handleStartMapping}
        disabled={!selectedState || !selectedHierarchy || selectedMetrics.length === 0}
        style={{
          width: '100%',
          padding: '15px',
          backgroundColor: (!selectedState || !selectedHierarchy || selectedMetrics.length === 0) 
            ? '#cccccc' 
            : '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: (!selectedState || !selectedHierarchy || selectedMetrics.length === 0) 
            ? 'not-allowed' 
            : 'pointer',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >
        Start Mapping
      </button>
    </div>
  );
};

export default MapSetUp;