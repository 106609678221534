import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


const sitemap = [
    {
      loc: 'https://terragrasp.com',
      display_name: 'Home',
    },
    {
      loc: 'https://terragrasp.com/about-us',
      display_name: 'About Us',
    },
    {
      loc: 'https://terragrasp.com/contact-us',
      display_name: 'Contact Us',
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/usa',
      display_name: 'Income by Zipcode',
    },
    {
      loc: 'https://terragrasp.com/user/cart',
      display_name: 'User Cart',
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/USA',
      display_name: "Income By Zipcode Usa"
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/alabama',
      display_name: "Income By Zipcode Alabama",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/alaska',
      display_name: "Income By Zipcode Alaska",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/arizona',
      display_name: "Income By Zipcode Arizona",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/arkansas',
      display_name: "Income By Zipcode Arkansas",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/california',
      display_name: "Income By Zipcode California",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/colorado',
      display_name: "Income By Zipcode Colorado",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/connecticut',
      display_name: "Income By Zipcode Connecticut",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/delaware',
      display_name: "Income By Zipcode Delaware",
    },
    {
      loc: 'https://terragrasp.com/income-by-zipcode/florida',
      display_name: "Income By Zipcode Florida",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/georgia',
      display_name: "Income By Zipcode Georgia",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/hawaii',
      display_name: "Income By Zipcode Hawaii",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/idaho',
      display_name: "Income By Zipcode Idaho",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/illinois',
      display_name: "Income By Zipcode Illinois",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/indiana',
      display_name: "Income By Zipcode Indiana",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/iowa',
      display_name: "Income By Zipcode Iowa",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/kansas',
      display_name: "Income By Zipcode Kansas",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/kentucky',
      display_name: "Income By Zipcode Kentucky",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/louisiana',
      display_name: "Income By Zipcode Louisiana",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/maine',
      display_name: "Income By Zipcode Maine",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/maryland',
      display_name: "Income By Zipcode Maryland",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/massachusetts',
      display_name: "Income By Zipcode Massachusetts",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/michigan',
      display_name: "Income By Zipcode Michigan",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/minnesota',
      display_name: "Income By Zipcode Minnesota",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/mississippi',
      display_name: "Income By Zipcode Mississippi",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/missouri',
      display_name: "Income By Zipcode Missouri",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/montana',
      display_name: "Income By Zipcode Montana",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/nebraska',
      display_name: "Income By Zipcode Nebraska",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/nevada',
      display_name: "Income By Zipcode Nevada",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/new_hampshire',
      display_name: "Income By Zipcode New Hampshire",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/new_jersey',
      display_name: "Income By Zipcode New Jersey",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/new_mexico',
      display_name: "Income By Zipcode New Mexico",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/new_york',
      display_name: "Income By Zipcode New York",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/north_carolina',
      display_name: "Income By Zipcode North Carolina",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/north_dakota',
      display_name: "Income By Zipcode North Dakota",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/ohio',
      display_name: "Income By Zipcode Ohio",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/oklahoma',
      display_name: "Income By Zipcode Oklahoma",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/oregon',
      display_name: "Income By Zipcode Oregon",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/pennsylvania',
      display_name: "Income By Zipcode Pennsylvania",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/rhode_island',
      display_name: "Income By Zipcode Rhode Island",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/south_carolina',
      display_name: "Income By Zipcode South Carolina",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/south_dakota',
      display_name: "Income By Zipcode South Dakota",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/tennessee',
      display_name: "Income By Zipcode Tennessee",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/texas',
      display_name: "Income By Zipcode Texas",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/utah',
      display_name: "Income By Zipcode Utah",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/vermont',
      display_name: "Income By Zipcode Vermont",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/virginia',
      display_name: "Income By Zipcode Virginia",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/washington',
      display_name: "Income By Zipcode Washington",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/west_virginia',
      display_name: "Income By Zipcode West Virginia",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/wisconsin',
      display_name: "Income By Zipcode Wisconsin",
      },
      {
      loc: 'https://terragrasp.com/income-by-zipcode/wyoming',
      display_name: "Income By Zipcode Wyoming",
      },
      {
        loc: 'https://terragrasp.com/state-income-data/alabama',
        display_name: "State Income Data Alabama",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/alaska',
        display_name: "State Income Data Alaska",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/arizona',
        display_name: "State Income Data Arizona",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/arkansas',
        display_name: "State Income Data Arkansas",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/california',
        display_name: "State Income Data California",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/colorado',
        display_name: "State Income Data Colorado",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/connecticut',
        display_name: "State Income Data Connecticut",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/delaware',
        display_name: "State Income Data Delaware",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/florida',
        display_name: "State Income Data Florida",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/georgia',
        display_name: "State Income Data Georgia",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/hawaii',
        display_name: "State Income Data Hawaii",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/idaho',
        display_name: "State Income Data Idaho",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/illinois',
        display_name: "State Income Data Illinois",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/indiana',
        display_name: "State Income Data Indiana",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/iowa',
        display_name: "State Income Data Iowa",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/kansas',
        display_name: "State Income Data Kansas",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/kentucky',
        display_name: "State Income Data Kentucky",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/louisiana',
        display_name: "State Income Data Louisiana",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/maine',
        display_name: "State Income Data Maine",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/maryland',
        display_name: "State Income Data Maryland",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/massachusetts',
        display_name: "State Income Data Massachusetts",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/michigan',
        display_name: "State Income Data Michigan",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/minnesota',
        display_name: "State Income Data Minnesota",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/mississippi',
        display_name: "State Income Data Mississippi",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/missouri',
        display_name: "State Income Data Missouri",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/montana',
        display_name: "State Income Data Montana",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/nebraska',
        display_name: "State Income Data Nebraska",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/nevada',
        display_name: "State Income Data Nevada",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/new_hampshire',
        display_name: "State Income Data New Hampshire",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/new_jersey',
        display_name: "State Income Data New Jersey",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/new_mexico',
        display_name: "State Income Data New Mexico",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/new_york',
        display_name: "State Income Data New York",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/north_carolina',
        display_name: "State Income Data North Carolina",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/north_dakota',
        display_name: "State Income Data North Dakota",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/ohio',
        display_name: "State Income Data Ohio",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/oklahoma',
        display_name: "State Income Data Oklahoma",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/oregon',
        display_name: "State Income Data Oregon",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/pennsylvania',
        display_name: "State Income Data Pennsylvania",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/rhode_island',
        display_name: "State Income Data Rhode Island",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/south_carolina',
        display_name: "State Income Data South Carolina",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/south_dakota',
        display_name: "State Income Data South Dakota",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/tennessee',
        display_name: "State Income Data Tennessee",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/texas',
        display_name: "State Income Data Texas",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/utah',
        display_name: "State Income Data Utah",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/vermont',
        display_name: "State Income Data Vermont",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/virginia',
        display_name: "State Income Data Virginia",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/washington',
        display_name: "State Income Data Washington",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/west_virginia',
        display_name: "State Income Data West Virginia",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/wisconsin',
        display_name: "State Income Data Wisconsin",
        },
        {
        loc: 'https://terragrasp.com/state-income-data/wyoming',
        display_name: "State Income Data Wyoming",
        },
  ];
  
  const SitemapLink = () => {
    return (
      <>
        <MKBox component="section">
          <Container sx={{ marginTop: '1vh', pb: { xs: 1, md: 10 } }}>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              borderRadius="16px"
              sx={{ 
                textAlign: "center", 
                background: 'white', 
                padding: '2rem'
              }}
            >
              <MKTypography variant="h1" mb={2} sx={{ textAlign: 'center' }}>
                Sitemap
              </MKTypography>
  
              <div style={{ textAlign: 'left', marginBottom: '1vh', width: '90%' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>TerraGrasp Site Map</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitemap.map((url, index) => (
                      <tr key={index}>
                        <td style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                          <li>
                            <a href={url.loc}>{url.display_name}</a>
                          </li>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Container>
        </MKBox>
      </>
    );
  };
  
  export default SitemapLink;



  