import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Fade} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";
import ButtonTable from '../basics/ButtonTable';
import { useCart } from "react-use-cart";
import StateImage from 'components/images/StateImages';  
import ExcelDisplay from './ExcelDisplay';
import Section from './Section';
import IncomeSchema from 'components/product/IncomeSchema';
import StateDropDown from 'components/basics/StateDropDown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { green,blue,grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4'; // Add this import

const ProductDetail = () => {
    const defaultState = {
        "description": "USA income by zipcode",
        "id": 92,
        "image_path": "https://terragrasp-images.s3.us-west-2.amazonaws.com/fun_states/USA.webp",
        "price": null,
        "product_name": "USA",
        "product_type": "income_by_zipcode",
        "product_year": 2023,
        "quantity": 1,
        "itemTotal": 19999}
    const [productList, setProductList] = useState([]);
    const [productDetails, setProductDetails] = useState(defaultState);
    const [images, setImages] = useState(false);
    const { addItem, getItem } = useCart();
    const [open, setOpen] = useState(true);
    const [seeOptions, setSeeOptions] = useState(false);
    const [addToCartWarning, setAddToCartWarning] = useState("");
    const {
        removeItem,
        items
    } = useCart();
    const navigate = useNavigate();
    const { stateName } = useParams();
    const canonicalUrl = `https://terragrasp.com/income-by-zipcode/${stateName}`;
    const marginSpace = '20px'; 
    // Add this useEffect hook at the beginning of the component
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (productList.length < 1) {
            axios.get(`${process.env.REACT_APP_API_URL}/products/products-type/income_by_zipcode`)
                .then(response => {
                    if (Array.isArray(response.data.data)) {
                        const sortedData = response.data.data.sort((a, b) => {
                            return a.product_name.localeCompare(b.product_name);
                        });
                        const usaProduct = sortedData.filter(item => item.product_name.toLowerCase() === 'usa');
                        const otherProducts = sortedData.filter(item => item.product_name.toLowerCase() !== 'usa');
                        const finalSortedData = [...usaProduct, ...otherProducts];
                        setProductList(finalSortedData);
                    }
                    setImages(prev => true);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }
        if (images) {
            setImages(false);
        }
        if (stateName) {
            const product = productList.find(p => p.product_name.toLowerCase() === stateName.toLowerCase());
            if (product) {
                setProductDetails(product);
            }
        }
    }, [images, productList, stateName]);
    
    const handleAddToCart =() => {
        const itemInCart = getItem(productDetails.id);
        let warning = false
        if (!itemInCart) {
            addItem(productDetails);
            setOpen(prev => true);
            setAddToCartWarning(prev => "Added To Cart!")
            
            // Track "Add to Cart" event
            ReactGA.event({
                category: 'Ecommerce',
                action: 'Add to Cart',
                label: productDetails.product_name,
            });
        } else {
            warning=true;
            setAddToCartWarning(prev => "This Item is already in your cart")
        }
        if (warning){
            setOpen(prev => true);
        }
    };

    const handlePurchaseUSA =() => {
        const usaProduct = productList.find(p => p.product_name.toLowerCase() === 'usa')
        const usaID = usaProduct.id
        items.forEach((item) => {
            if (item.id !== usaID) {
                removeItem(item.id);
            }
        });
        addItem(usaProduct);

        ReactGA.event({
            category: 'Ecommerce',
            action: 'Buy All US Data',
            label: 'USA',
        });

        navigate('/user/cart');
    };

    const handleCheckout = () => {
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Checkout',
            label: 'Cart',
        });
    };

    const handleProductChange =(selectValue) => {
        setOpen(prev => false);
        const product = productList.find(p => p.product_name === selectValue);
        setProductDetails(product);
        navigate(`/income-by-zipcode/${selectValue.replace(/ /g, '_')}`, { replace: true });
    };

    const targetProductInfo = useRef(null);
    const handleScrollToProductInfo = () => {
        handleScrollToSectionWithPadding(targetProductInfo)
    };

    const targetDataPoints = useRef(null);
    const handleScrollToDataPoints = () => {
        handleScrollToSectionWithPadding(targetDataPoints)
    };
    
    const handleShowAllOptions = () => {
        setSeeOptions(value=>!value)
    };

    const DownloadSample = () => {
        const fileUrl = 'https://terragrasp-images.s3.us-west-2.amazonaws.com/sample_products/TerraGrasp_Income_by_zip_Sample_Data.xlsx';
        window.location.href = fileUrl;
    };
    
    // Add this function to generate structured data
    const generateStructuredData = () => {
        return {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${productDetails.product_name} Income By Zipcode`,
            "description": `Median Income data visualization by zipcode for ${productDetails.product_name} product page`,
            "image": {
                "@type": "ImageObject",
                "contentUrl": StateImage(productDetails.product_name.replace(/ /g, '_')),
                "name": `${productDetails.product_name} Income by Zipcode`,
                "description": `Median Income data visualization by zipcode for ${productDetails.product_name}`,
            },
            "url": `${window.location.origin}/income-by-zipcode/${productDetails.product_name.replace(/ /g, '_')}`
        };
    };
    const getStateName = () => {
        if (productDetails.product_name.toLowerCase() === 'usa') {
            return ' the USA';
        }
        return productDetails.product_name.replace(/_/g, ' ') || 'your state';
    };

    const handleScrollToSectionWithPadding = useCallback((ref) => {
        if (ref.current) {
            const topPosition = ref.current.getBoundingClientRect().top + window.scrollY;
            const padding = window.innerHeight * 0.075; // 10% of the viewport height
            window.scrollTo({
                top: topPosition - padding,
                behavior: 'smooth',
            });
        }
    }, []);

    const handleCheckoutWithProduct = () => {
        const itemInCart = getItem(productDetails.id); // Check if the item is already in the cart
        if (!itemInCart) {
            addItem(productDetails); // Add item only if it's not in the cart
        }
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Checkout with Product',
            label: productDetails.product_name,
        });

        navigate('/user/cart'); // Navigate to the cart
    };

    // Extracted function for rendering product details
    const renderProductDetails = () => (
        <CardContent>
            <Grid container direction="column" sx={{ height: 'auto' }}>
                <Grid item sx={{ mb: 1 }}>
                    <MKTypography variant="h4" sx={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {productDetails["price"] > 0 ? getStateName() : 'Select An Option'}
                    </MKTypography>
                </Grid>
                <Grid item>
                    <MKTypography variant="h6" sx={{ textAlign: 'left', mt: 1, whiteSpace: 'normal', lineHeight: 1.2 }}>
                        {productDetails["price"] > 0 ? `Income By Zipcode Report` : `Income By Zipcode Report Starting at $49`}
                    </MKTypography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MKTypography variant="h4" sx={{ textAlign: 'left' }}>
                        {productDetails["price"] > 0 ? (
                            `$${productDetails["price"] / 100}`
                        ) : (
                            ``
                        )}
                    </MKTypography>
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="h4" sx={{ textAlign: 'left' }}>
                        Select A State
                    </MKTypography>
                </Grid>
                <Grid item xs={12}>
                    <MKBox mt={-1} textAlign="left">
                        <StateDropDown
                            onSelectButton={handleProductChange}
                            products={productList}
                            initialSet={stateName ? stateName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) : ''}
                        />
                    </MKBox>
                </Grid>
                    <Grid item xs={12}>
                        <MKBox >
                            <MKButton onClick={() => handleCheckoutWithProduct()} variant="gradient" color="success" sx={{ fontSize: '18px', width:"80%" }}>
                                Buy Now for ${productDetails.price ? (productDetails.price / 100) : 'N/A'}
                            </MKButton>
                        </MKBox>
                    </Grid>
            <Grid item xs={12}>

            <MKBox mt={3} mb={.5}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <MKButton onClick={() => handleAddToCart()} variant="gradient" color="info" sx={{ fontSize: '18px', width:"100%"}}>
                            Add to cart
                        </MKButton>
                    </Grid>
                    <Grid item>
                        <MKButton component={Link} to="/user/cart" variant="gradient" color="warning" sx={{ fontSize: '18px', width:"100%" }} onClick={handleCheckout}>
                            Checkout 
                        </MKButton>
                    </Grid>
                </Grid>
            </MKBox>
            </Grid>
                <Grid item xs={4}>
                    <MKBox mt={-1} textAlign="center">
                        <MKTypography onClick={handleScrollToDataPoints} variant="button" color="info" fontWeight="medium" textGradient sx={{ cursor: 'pointer' }}>
                            See Data Points
                        </MKTypography>
                    </MKBox>
                </Grid>
                <Grid item xs={4}>
                    <MKBox mt={-1} textAlign="center">
                        <MKTypography onClick={handleScrollToProductInfo} variant="button" color="info" fontWeight="medium" textGradient sx={{ cursor: 'pointer' }}>
                            Product Info
                        </MKTypography>
                    </MKBox>
                </Grid>
                <Grid item xs={4}>
                    <MKBox mt={-1} textAlign="center">
                        <MKTypography onClick={handleShowAllOptions} variant="button" color="info" fontWeight="medium" textGradient sx={{ cursor: 'pointer' }}>
                            See All Options
                        </MKTypography>
                    </MKBox>
                </Grid>
            </Grid>
            <Grid container alignItems="top" sx={{ height: '10px', borderBottom: '1px solid black' }} />
            <Fade in={open}>
                <div id="example-fade-text">
                    {addToCartWarning}
                </div>
            </Fade>
            <MKBox mt={3} mb={.5}>
            <Grid item xs={12}>
                    <MKBox >
                        <MKButton onClick={handlePurchaseUSA} to="/user/cart" variant="gradient" color="primary" sx={{ fontSize: '18px', width:"80%" }}>
                            Buy All US Data $199
                        </MKButton>
                </MKBox>
                </Grid>
            </MKBox>
        </CardContent>
    );

    return (
        <>
    <Helmet>
        <title>{`${getStateName()} Income By Zipcode`}</title>
        <meta name="description" content={`Median Income data visualization by zipcode for ${getStateName()}`} />
        <script type="application/ld+json">
            {JSON.stringify(generateStructuredData())}
        </script>
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>
    <MKBox component="section" py={1}>
    <Container>
        <Grid container item 
            flexDirection="column"
            alignItems="center"
            borderRadius='16px'
            xs={12}
            sx={{ textAlign: "center", background: 'white', mx: "auto" }}
            lg={12} >
            <MKTypography variant="h2" xs={1}>
                Income By Zipcode
            </MKTypography>
            <Grid container spacing={1} flexDirection="column" pt={2}>
                <Box sx={{ pt: { xs: 1, md: .1 }, pb: { xs: 1, md: .1 } }}>
                    <Grid container>
                        <Grid item xs={.2} md={.2} />
                        <Grid item xs={12} md={5.8}>
                            <Box sx={{
                                height: '90%',  
                                width: '100%',  
                                background: 'white',
                                padding: 1,
                                margin: 2,
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                overflow: 'hidden',
                                maxHeight: '500px' 
                            }}>
                                <img 
                                    loading="lazy" 
                                    src={StateImage(productDetails["product_name"].replace(/ /g, '_'))}
                                    alt={`Median Income data visualization by zipcode for ${getStateName()}`}
                                    style={{
                                        maxWidth: '100%',  
                                        maxHeight: '100%',
                                        objectFit: 'contain',  
                                        width: 'auto',  
                                        height: 'auto'  
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={.2} />
                        <Grid item xs={12} md={5.8} >
                            {renderProductDetails()}
                        </Grid>
                        <Grid item xs={.2} md={.2} />
                    </Grid>
                </Box>
            </Grid>
            {seeOptions ? (
                <Grid container spacing={1} flexDirection="column" pt={-2}>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                        padding: .1,
                        margin: 2,
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <MKTypography variant="h3" mt={1} mb={1} sx={{ textAlign: 'Center' }}>
                            Select A State
                        </MKTypography>
                        <ButtonTable
                            onSelectButton={handleProductChange}
                            products={productList}
                        />
                    </Box>
                </Grid>
                <Grid item xs={1} md={1} />
                </Grid>
            ):(<></>)}
            </Grid>
        </Container>
    </MKBox>
    <MKBox component="section" py={3} sx={{ textAlign: "center", mx: "auto" }} >
        <Grid container flexDirection="column" alignItems="center"
            sx={{ 
                textAlign: "center", 
                background: 'dark', 
            }}
        >
            <MKTypography variant="h3" mb={2}>
                Product Info
            </MKTypography>
            <div style={{ textAlign: 'left', marginBottom: '1vh', maxWidth: '70%' }}>
                <h3>Comprehensive Income Data by Zip Code for {getStateName()}</h3>
                <p>Unlock the power of detailed income data tailored to {getStateName()} with our comprehensive income database by zip code. This product is designed to provide in-depth insights into various income brackets, median and mean household incomes, and retirement income data points for {getStateName()}, enabling industries to make data-driven decisions and strategic planning specific to this state.</p>
                <Section />
            </div>
        </Grid>
    </MKBox>
    <MKBox component="section" py={3} sx={{ textAlign: "center", background: '#403d39', mx: "auto", color:"#ffffff" }}>
        <Grid container flexDirection="column" alignItems="center"
            sx={{ 
                textAlign: "center", 
            }}
        >
            <div style={{ textAlign: 'center'}}>
                <MKTypography variant="h3" mb={2} color="#ffffff">
                    Take a look for yourself!
                </MKTypography>
                <p>
                    Still have questions? Take a look at this sample set to get a better understanding of what you are purchasing.
                </p>
                <MKBox mt={3} mb={5}>
                    <MKButton onClick={DownloadSample} variant="gradient" color="warning" sx={{ fontSize: '18px', width:"60%"}}>
                        Download Sample Data!
                    </MKButton>
                </MKBox>
            </div>
            <div style={{ textAlign: 'Center', maxWidth: '70%', marginTop: {marginSpace}, marginBottom: {marginSpace}, color:"#ffffff" }}>
                <MKTypography variant="h3" mb={2} color="#ffffff">
                    Preview Of Sheet 1
                </MKTypography>
                <ExcelDisplay />
            </div>
        </Grid>
    </MKBox>
    <MKBox component="section" py={3} sx={{ textAlign: "center", background: 'white', mx: "auto" }}>
        <Grid container flexDirection="column" alignItems="center"
            sx={{ 
                textAlign: "center", 
                background: 'dark', 
            }}
        >
            <div style={{ textAlign: 'left',  maxWidth: '70%' }}>
                <h3>Industry Applications in {getStateName()}:</h3>
                <ul>
                    <li><strong>Real Estate and Housing Development:</strong>
                        <ul>
                            <li>Identify high-income areas for luxury housing projects.</li>
                            <li>Analyze retirement income data to develop retirement communities or senior living facilities.</li>
                        </ul>
                    </li>
                    <li><strong>Retail and Consumer Goods:</strong>
                        <ul>
                            <li>Tailor marketing strategies based on the income levels and age demographics of different zip codes.</li>
                            <li>Optimize store locations to align with target customer bases.</li>
                        </ul>
                    </li>
                    <li><strong>Healthcare and Pharmaceuticals:</strong>
                        <ul>
                            <li>Use age-specific income data to plan healthcare facilities and services catering to different age groups.</li>
                            <li>Focus on areas with high retirement income for senior healthcare services.</li>
                        </ul>
                    </li>
                    <li><strong>Financial Services:</strong>
                        <ul>
                            <li>Develop financial products and services targeting high-income households.</li>
                            <li>Provide tailored retirement planning and investment services for areas with significant retiree populations.</li>
                        </ul>
                    </li>
                    <li><strong>Public Policy and Urban Planning:</strong>
                        <ul>
                            <li>Utilize income and demographic data to plan social services and infrastructure.</li>
                            <li>Address income disparity and support community development programs.</li>
                        </ul>
                    </li>
                    <li><strong>Marketing and Advertising:</strong>
                        <ul>
                            <li>Design targeted advertising campaigns based on the income and age demographics of specific regions.</li>
                            <li>Plan media buys and promotional activities that resonate with the local economic conditions.</li>
                        </ul>
                    </li>
                </ul>
                <h3>Comprehensive Data Points for {getStateName()} Include:</h3>
                <ul>
                    <li><strong>Income Brackets:</strong> Detailed breakdown from less than $10,000 to over $200,000.</li>
                    <li><strong>Median and Mean Income:</strong> Essential statistics for economic analysis.</li>
                    <li><strong>Retirement Income:</strong> Data on households with and without retirement income.</li>
                    <li><strong>Age-Specific Income:</strong> Insights into income distribution across different age groups.</li>
                    <li><strong>Household Composition:</strong> Data on household sizes and the number of earners.</li>
                </ul>
                <p>Our income data by zip code product for {getStateName()} is a powerful tool for businesses and organizations looking to leverage demographic and economic insights for strategic growth 
                    and development within the state. Equip your team with the data they need to make informed decisions and drive success in your industry in {getStateName()}.
                </p>
            </div>
            </Grid>
    </MKBox>
    <MKBox component="section" py={1} sx={{ textAlign: "center", background: '#403d39', mx: "auto", color: '#ffffff' }}>
        <Grid container flexDirection="column" alignItems="center"
            sx={{ 
                textAlign: "center", 
                background: 'dark', 
            }}
        >
            <div style={{ textAlign: 'Center', maxWidth: '70%', marginBottom: '2vh'}} ref={targetDataPoints}>
                <MKTypography variant="h3" mb={2} color='#ffffff'>
                    See All Data Fields
                </MKTypography>
                <div style={{ textAlign: 'left', marginBottom: '1vh', maxWidth: '100%' }}>
                    <p>
                        The data fields are organized into a hierarchy consisting of <strong><em>Category</em></strong> and <strong><em>Category Detail</em></strong>. The <strong><em>Category</em></strong> column represents the general type of data collected, 
                        while the <strong><em>Category Detail</em></strong> column specifies the particular data points within that category, such as specific age groups, income brackets, or other breakdowns. 
                        Each data point includes both an <strong>estimate</strong> and an associated margin of <strong>error</strong>. 
                        This means that every <strong><em>Category Detail</em></strong> comes with its respective <strong>estimate</strong> and <strong>error</strong> values.
                    </p>
                    <div style={{ textAlign: 'left', marginLeft: '10%', marginBottom: '1vh', maxWidth: '90%' }}>
                        <p>
                            <strong>The <em>Category</em> column uses symbols to represent the nature of the data:</strong>
                        </p>
                        <ul>
                            <li>
                                <AttachMoneyIcon sx={{ color: green[500] }} /> indicates a monetary value.
                            </li>
                            <li>
                                <NumbersIcon sx={{ color: grey[500] }} />  represents a count or number.
                            </li>
                            <li>
                                <PercentIcon sx={{ color: blue[500] }} /> denotes a percentage.
                            </li>
                        </ul>
                    </div>
                    <p>
                        This structure ensures that all data points are clearly categorized while also conveying the type of measurement each represents.
                    </p>
                </div>
                <IncomeSchema />
            </div>
            </Grid>
    </MKBox>
    <MKBox component="section" py={2} sx={{ textAlign: "center", mx: "auto" }}>
        <Grid container flexDirection="column" alignItems="center"
            sx={{ 
                textAlign: "center", 
                background: 'dark', 
            }}
        >
            <div style={{ textAlign: 'left', marginTop: {marginSpace}, marginBottom: {marginSpace}, maxWidth: '70%' }}>
                <div style={{ textAlign: 'left' }}>
                    <h3>Important Data Details</h3>
                    <p>When you purchase data for {getStateName()}, you may notice that some zip codes are missing data for certain metrics or even entirely.
                        This can be due to various factors such as data suppression for privacy protection, non-response from individuals, data collection issues, or the non-applicability
                        of certain questions in {getStateName()}. These missing values originate directly from the census and are unavailable to the public. If your report for {getStateName()} is missing something important,
                        please don't hesitate to contact us for help.</p>
                </div>
            </div>
            <div style={{ textAlign: 'left', marginBottom: '5vh', maxWidth: '70%' }}>
                <p>
                    The data is sourced from the latest publicly available U.S. Census data, ensuring you have access to the most current and relevant information for the state. Whether you're 
                    conducting market research, analyzing economic trends, or planning business strategies in {getStateName()}, our data provides the foundation you need to succeed.
                </p>
                <p>
                    Invest in your {getStateName()}-specific income data today and empower your decisions with accuracy and comprehensive insights. 
                    Transform raw data into strategic knowledge and stay ahead in your field.
                </p>
            </div>
        </Grid>
</MKBox>

        </>

    );
};
export default ProductDetail;

